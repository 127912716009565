import React from "react";
import { Button } from "react-bootstrap";

export default function StudentPayment({ item, studentList, saleRepList, breakdown }) {
  return (
    <div className="row m-0 p-0">
      {/* Issue Date Entry */}
      <div className="col border py-2">
        {new Intl.DateTimeFormat("en-GB", {
          year: "numeric",
          month: "long",
          day: "2-digit",
        }).format(new Date(item.issue_date))}
      </div>
      {/* Student Name Entry */}
      <div className="col border py-2">
        {studentList.filter((student) => student.uid === item.studentid).length>0&&
        studentList.filter((student) => student.uid === item.studentid)[0]
          .firstname +
          " " +
          studentList.filter((student) => student.uid === item.studentid)[0]
            .lastname
          }
      </div>
      {/* Pay From Entry */}
      <div className="col border py-2">
        {new Intl.DateTimeFormat("en-GB", {
          year: "numeric",
          month: "long",
          day: "2-digit",
        }).format(new Date(item.period_start))}
      </div>
      {/* Pay to Entry */}
      <div className="col border py-2">
        {new Intl.DateTimeFormat("en-GB", {
          year: "numeric",
          month: "long",
          day: "2-digit",
        }).format(new Date(item.period_end))}
      </div>
      {/* Collected Entry */}
      <div
        className="col border py-2"
        style={{ background: "rgba(0,0,0,0.2)" }}
      >
        {(item.total - item.outstanding).toFixed(2)}
      </div>

      {/* Breakdown Entry*/}
      <div
        className="col border py-2"
        style={{ background: "rgba(0,0,0,0.3)" }}
      >
        {breakdown&&breakdown.toFixed(2)}
      </div>
      {/* Sale Rep Entry */}
      <div className="col border py-2">
        {saleRepList &&
          saleRepList.filter((sale) => item.sale_rep_id === sale.uid)[0].name}
      </div>
      {/* Commission Entry */}
      <div className="col border py-2">
        ${item.commissions &&
          (((item.total - item.outstanding).toFixed(2) * item.commissions) / 100).toFixed(1)}
      </div>
      {/* <div className="col border py-2">
        <Button 
          variant="link"
          onClick={()=>setEditPayMentForm(item.uid)}
        >Edit</Button>
      </div> */}
    </div>
  );
}
