import { useEffect, useState } from "react"
import { Button, FloatingLabel, Form, Dropdown} from "react-bootstrap"

import Calendar from 'react-calendar';
import { useDispatch, useSelector } from "react-redux"
import PaymentView from "../../components/paymentView";
import ContactView from "../../components/contactView";

import API from "../../API";
import 'react-calendar/dist/Calendar.css';


export default function Report() {

    const [DbAccess, setDbAcess] = useState(useSelector(state=>state.DbAccess))
    const role = useSelector(state => state.role)
    const token = useSelector(state => state.token)
    const breakdown = ['All', 'By month', 'By year']
    const [showCalendar, setsShowCalendar] = useState(false)
    const [breakdownPeriod, setBreakdownPeriod] = useState('All')
    const [paymentView, setPaymentView] = useState(true)
    
    const [studentNameInput, setStudentNameinput] = useState(null)
    const [location, setLocation] = useState()
    const [packageType, setPackageType]= useState('All Package')
    const [collectedBracket, setCollectedBracket] = useState('All')
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [saleRepName, setSaleRepName] = useState(null);

    const [collectedAmount, setCollectedAmount] = useState(0)
    const [breakdownAmount, setBreakdownAmount] = useState(0)


    const handleDateBracket = (type, date) =>{
        setStartDate(new Date(date))
        if(type==='By month'){
            setEndDate(new Date (new Date(date).getFullYear(), new Date(date).getMonth()+1, 0))
            setCollectedBracket(
                new Date(date).toLocaleString('default', { month: 'long' })+', '+new Date(date).getFullYear()
            )
        }else{
            setEndDate(new Date (new Date(date).getFullYear(), new Date(date).getMonth()+12, 0))
            setCollectedBracket(new Date(date).getFullYear())
        }
        setsShowCalendar(!showCalendar)
    }

    useEffect(()=>{
        API.getLocationList(token)
        .then(response=>{
            setLocation(response.data)
        })
    },[])
    return(
        // Time period dropdown selection
        <div className="h-100 w-100">
            <div className="d-flex flex-column m-0 justify-content-end w-75 mx-auto" style={{height:paymentView?'25%':'10%'}}>
                {paymentView&&<div className="d-flex align-items-center mb-4">
                    <p className="m-0">Breakdown</p>
                    <Dropdown className="px-2">
                            <Dropdown.Toggle 
                                className="bg-custom secondary-font"
                            >
                                {breakdownPeriod}
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="drop-shadow mb-0">
                                {breakdown.map((item, index)=>
                                <Dropdown.Item
                                    key={index}
                                    onClick={()=> {
                                        setBreakdownPeriod(item)
                                        setCollectedBracket('All')
                                        setStartDate(null)
                                        setEndDate(null)
                                    }}
                                >
                                    {item}
                                </Dropdown.Item>)}
                            </Dropdown.Menu>
                    </Dropdown>
                    {breakdownPeriod!=='All'&&
                    <div className="d-flex m-0">
                        <Button
                            variant='outline-dark'
                            onClick={()=>{
                                setsShowCalendar(!showCalendar)
                            }}
                        >
                            {collectedBracket}
                        </Button>
                        {breakdownPeriod!=='All'&&showCalendar&&
                            <div
                            className="position-relative mx-2"
                            style={{zIndex:1000, width:'500px'}}
                            >
                                <Calendar 
                                    className='position-absolute drop-shadow'
                                    view={breakdownPeriod === 'By month'&&"year"||breakdownPeriod === 'By year'&&"decade"}
                                    onClickMonth={(date) => handleDateBracket(breakdownPeriod, date)}
                                    onClickYear={(date) => handleDateBracket(breakdownPeriod, date)}
                                />
                            </div>
                        }
                    </div>}
                </div>}
                {paymentView&&
                <div className="d-flex font-16pt">
                    <div className="text-end">
                        <p className="m-0 mb-2 prime-font">Collected:</p>
                        <p className="m-0 mb-2 prime-font">Breakdown:</p>
                    </div>
                    <div className="text-start ps-2">
                        <p className="m-0 mb-2">${collectedAmount}</p>
                        <p className="m-0 mb-2">${breakdownAmount}</p>
                    </div>
                </div>}
            </div>

            {/* Search and filter */}
            <div className="secondary-font row align-items-center mx-auto" style={{width:'90%'}}>
                <div className="col">
                {!paymentView&&<FloatingLabel label="Search by Student Name" className="text-custom">
                <Form.Control
                    placeholder="text"
                    className="border-0 border-bottom"
                    onChange={(e)=>setStudentNameinput(e.target.value)}
                />
                </FloatingLabel>
                }
                </div>
                {role==='admin'&&
                <Dropdown className="px-2 col-3">
                    <Dropdown.Toggle
                        variant='outline-dark'
                        className="secondary-font w-100 text-start"
                    >
                        {DbAccess==='all'?'All Locations':location&&location.filter(item=>item.uid === DbAccess)[0].name}
                    </Dropdown.Toggle>
                        <Dropdown.Menu className="drop-shadow mb-0">
                        <Dropdown.Item onClick={()=>setDbAcess('all')}>All Locations</Dropdown.Item>
                        {location&&location.map((item, index)=>
                            <Dropdown.Item key={index} onClick={()=>setDbAcess(item.uid)}>{item.name}</Dropdown.Item>
                        )}
                    </Dropdown.Menu>
                </Dropdown>}
                {role==='admin'&&
                <Dropdown className="px-2 col-2">
                    <Dropdown.Toggle
                        variant='outline-dark'
                        className="secondary-font w-100 text-start"
                    >
                        {packageType?packageType:'All Package'}
                    </Dropdown.Toggle>
                        <Dropdown.Menu className="drop-shadow mb-0">
                            <Dropdown.Item onClick={()=>setPackageType('All Package')}>All Package</Dropdown.Item>
                            <Dropdown.Item onClick={()=>setPackageType('A')}>A</Dropdown.Item>
                            <Dropdown.Item onClick={()=>setPackageType('B')}>B</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>}
                {/* Sale Rep Input Field */}
                <FloatingLabel label="Enter Sale Rep Name" className="text-custom col d-none">
                <Form.Control
                    placeholder="text"
                    className="border-0 border-bottom"
                    onChange={(e)=>setSaleRepName(e.target.value)}
                />
                </FloatingLabel>
                <Button className="col-2 bg-custom"
                    onClick={()=>setPaymentView(!paymentView)}
                >
                    {paymentView?'Contact view':'Payment View'}
                </Button>
            </div>
            <div className="mx-auto mt-3" style={{width:'90%'}}>
                {paymentView?
                    <PaymentView 
                        filter={{
                            // 'studentNameInput': studentNameInput, 
                            'location': DbAccess, 
                            'packageType': packageType,
                            'startDate':startDate,
                            'endDate':endDate,
                            'saleRepName': saleRepName,
                        }}
                        token={token}
                        setBreakDownAmount={setBreakdownAmount}
                        setCollectedAmount={setCollectedAmount}
                    />
                    :
                    <ContactView 
                        filter={{
                            'studentNameInput': studentNameInput, 
                            'location': DbAccess, 
                            'packageType': packageType,
                        }}
                        token={token}
                    />
                }
            </div>
        </div>
    )
}