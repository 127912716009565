import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";

export default function Feedback(props) {
    const dispatch = useDispatch();
    useEffect(()=>{
        setTimeout(()=>{ dispatch({ type: 'SHOW_FEED_BACK', payload: false }) }, 3000)
    },[])
    const handleClick = () => {
       dispatch({ type: 'SHOW_FEED_BACK', payload: false })
    }    
    return(
        <div 
            className={`bg-${props.type} text-white fixed-bottom shadow`}
            style={{bottom:'20px', width: '270px', right: '10px', left: 'auto', zIndex: 10000}}    
        >
                <Button 
                        variant='link'
                        className="border-0 d-block me-0 ms-auto pb-0" 
                        onClick={()=> handleClick()}
                >
                        <i className="fas fa-times text-white"></i>
                </Button>
            <p className="text-white text-start px-2">{props.text}</p>
        </div>

    )
}