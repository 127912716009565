import { Form, FloatingLabel, Button} from "react-bootstrap"
import React,{useRef} from "react";
import API from "../API/index";
import { useSelector } from "react-redux";

export default function ContactForm(props) {
    const relationCheck= useRef()
    const nameCheck= useRef()
    const cellCheck= useRef()
    const parentEmailCheck= useRef()
    let token = useSelector(state=>state.token)
    const handleClick=()=>{
        props.setContactForm&&props.setContactForm(false)
    }
    const updateStudentContact=()=>{
        API.updateStudentContact(token,props.info.uid,relationCheck.current.value, nameCheck.current.value,
             cellCheck.current.value, parentEmailCheck.current.value)
             .then((response)=>{
                    props.RetrieveStudent(response.data.student_contacts.studentid)
                    props.setContactForm&&props.setContactForm(false)
             })
             .catch((error)=>
             console.log(error))
    }
    return(
        <div className="h-100 w-100 position-absolute shadow-overlay d-flex align-items-center">
        <div className="overflow-auto w-75 position-relative m-auto ps-2" style={{height:'80%'}}>
            <div className="row mx-2 p-2 mb-3 drop-shadow rounded bg-white">
            <h4 className="text-custom text-left prime-font">Parent/Guardian</h4>
            <FloatingLabel
                label='Relation'
                className="col-6 text-custom secondary-font px-3 mb-3"
            >   
                <Form.Control 
                    placeholder="Relation"
                    className='border-0 border-bottom'  
                    ref={relationCheck}
                />
            </FloatingLabel>
            <FloatingLabel
                label='Name'
                className="col-6 text-custom secondary-font px-3 mb-3"
            >   
                <Form.Control 
                    placeholder="Name"
                    className='border-0 border-bottom'  
                    ref={nameCheck}
                />
            </FloatingLabel>
            <FloatingLabel
                label='Cell'
                className="col-6 text-custom secondary-font px-3 mb-3"
            >   
                <Form.Control 
                    placeholder="Cell"
                    className='border-0 border-bottom'  
                    ref={cellCheck}
                />
            </FloatingLabel>
            <FloatingLabel
                label='Email'
                className="col-6 text-custom secondary-font px-3 mb-3"
            >   
                <Form.Control 
                    placeholder="Email"
                    className='border-0 border-bottom'  
                    ref={parentEmailCheck}
                />
            </FloatingLabel>
                <div className="mb-2 mt-4 me-auto ms-0 d-flex justify-content-end">
                    <Button 
                        className="bg-custom mx-5 text-center"
                        onClick={()=> updateStudentContact()}
                        >
                            Create
                    </Button>
                    <Button 
                        className="bg-custom mx-5 text-center mx-2">
                    Clear
                    </Button>
                    <Button 
                        className="bg-custom mx-5 text-center"
                        onClick={()=> handleClick()}
                      >
                    Cancel
                    </Button>
                </div>
            </div>
        </div>
    </div>
    )
}
