import { useState, useEffect } from "react"
import API from "../API"
import StudentPayment from "./studentPaymentRow"


export default function PaymentView (props) {
    const labelRow = ['Issue Date', 'Name', 'Pay From', 'Pay To', 'Collected', 'Breakdown','Sale Rep', 'Commission']
    const [studentList, setStudentList] = useState()
    const [paymentList, setPaymentList] = useState()
    const [paymentid, setPaymentid] = useState([])
    const [saleRepList, setSaleRepList] = useState()
    const [breakdown, setBreakdown] = useState([])
    const [collected, setCollected] = useState([])
    
    useEffect(()=>{
        setPaymentid([])
        API.getStudentList(props.token, props.filter.location)
        .then(response=>{
            response.data.forEach(item=>setPaymentid(uid=>uid.concat(item.uid)))
            setStudentList(response.data)

        })
        props.setBreakDownAmount(0)
        props.setCollectedAmount(0)

    },[props.filter.location])
    useEffect(()=>{
        API.getSaleList(props.token)
        .then(response=>
            setSaleRepList(response.data)
        )
         API.getStudentBillsList(props.token, paymentid)
        .then(response=>
            setPaymentList(response.data)
        )
    },[studentList])

    useEffect(()=>{
        setBreakdown([])
        setCollected([])
        paymentList&&studentList&&studentList.length>0&&studentList
            .filter(item=>props.filter.studentNameInput&&
                (item.firstname.toLowerCase().indexOf(props.filter.studentNameInput.toLowerCase()) !== -1
                ||
                item.lastname.toLowerCase().indexOf(props.filter.studentNameInput.toLowerCase()) !== -1)
            )&&paymentList
            .filter(item=>props.filter.packageType!=='All Package'?item.package_type===props.filter.packageType:item)
            .filter(item=>props.filter.startDate&&props.filter.endDate?
                new Date(item.period_start)<=new Date(props.filter.endDate)
                &&new Date(item.period_end)>=new Date(props.filter.startDate)
                :
                item
        ).forEach(item=>{

            let totalDate = Math.ceil((new Date(item.period_end)/(1000 * 3600 * 24 + 1) - new Date(item.period_start)/(1000 * 3600 * 24 + 1))+1)
            let breakdownPeriod = Math.ceil((Math.min(new Date(item.period_end)/(1000 * 3600 * 24 + 1), new Date(props.filter.endDate)/(1000 * 3600 * 24 + 1)) - 
                                            Math.max(new Date(item.period_start)/(1000 * 3600 * 24 + 1), new Date(props.filter.startDate)/(1000 * 3600 * 24 + 1)))+1);
            props.filter.endDate&&props.filter.startDate?
                breakdownPeriod>0&&setBreakdown(breakdown=>[...breakdown,((item.total-item.outstanding)/totalDate)*breakdownPeriod])
                :
                setBreakdown(collected)
            setCollected(collected=>[...collected, item.total-item.outstanding])
        })
    },[props.filter, paymentList])

    const saleRepNameFilter = (student) => {
        if (!props.filter.saleRepName) return true;
        let saleRepName = saleRepList.filter(saleRep => student.sale_rep_id === saleRep.uid)[0].name;
        return saleRepName.toLowerCase().indexOf(props.filter.saleRepName.toLowerCase()) !== -1;
    };

    useEffect(()=>{
        props.setCollectedAmount(collected.reduce((a, b) => a + b, 0).toFixed(2))
        props.setBreakDownAmount(breakdown.reduce((a, b) => a + b, 0).toFixed(2))
    },[collected, breakdown])
    return(
        <div>
            <div className="row m-0 prime-font text-white" style={{background:'rgba(0,0,0,0.5)'}}>
                {labelRow.map((item, index)=>
                    <div key={index} className="col border py-2">{item}</div>
                )}
            </div>
            <div>
                {/* TODO: studentList got filtered, but never got set, so we need to set filtered student list */}
        {paymentList&&studentList&&studentList.length>0&&studentList
            .filter(item=>props.filter.studentNameInput&&
                (item.firstname.toLowerCase().indexOf(props.filter.studentNameInput.toLowerCase()) !== -1
                ||
                item.lastname.toLowerCase().indexOf(props.filter.studentNameInput.toLowerCase()) !== -1)
            )
            &&paymentList
            .filter(item=>props.filter.packageType!=='All Package'?item.package_type===props.filter.packageType:item)
            .filter(item=>saleRepNameFilter(item))
            .filter(item=>props.filter.startDate&&props.filter.endDate?
                new Date(item.period_start)<=new Date(props.filter.endDate)
                &&new Date(item.period_end)>=new Date(props.filter.startDate)
                :
                item
        ).map((item, index)=>
            <div key={index} className="row m-0 hover-scale p-0">
                <StudentPayment 
                    item={item} 
                    studentList={studentList} 
                    saleRepList={saleRepList}
                    breakdown={breakdown[index]}
                />
            </div>
        )}
            </div>
        </div>
    )
}