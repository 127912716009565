import { useState } from "react";
import { Button, FloatingLabel, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import jwtDecode, * as jwt_decode from 'jwt-decode'
import API from "../API";

export default function ChangePasswordForm(props) {
    const token = useSelector(state=>state.token)
    const inputCommonClass = 'text-custom secondary-font px-3 my-3'
    const [oldPassword, setOldPassword]= useState()
    const [newPassword, setNewPassword]= useState()
    const [confirmNewPassword, setConfirmNewPassword]= useState()
    const decode= jwtDecode(token)
    const dispatch = useDispatch();
    const changePassword=()=>{
        oldPassword&&newPassword&&confirmNewPassword?(
        newPassword===confirmNewPassword?(
        API.changepassword(token, decode.id , oldPassword, newPassword, confirmNewPassword)
        .then((response)=>{
        })
        .catch((error)=>{
            dispatch({ type: 'FEED_BACK_TYPE', payload: 'danger'})&&
            dispatch({ type: 'FEED_BACK_TEXT', payload: error.response.data.error})&&
            dispatch({ type: 'SHOW_FEED_BACK', payload: true })
        }
        )&&
        props.setChangePassword(false)
        )
        :
        (
        dispatch({ type: 'FEED_BACK_TYPE', payload: 'danger'})&&
        dispatch({ type: 'FEED_BACK_TEXT', payload: 'New password does not match'})&&
        dispatch({ type: 'SHOW_FEED_BACK', payload: true })
        ))
        :
        (
        dispatch({ type: 'FEED_BACK_TYPE', payload: 'danger'})&&
        dispatch({ type: 'FEED_BACK_TEXT', payload: 'Please fill in required field'})&&
        dispatch({ type: 'SHOW_FEED_BACK', payload: true })
        )
    }
   
    return(
        <div 
        className="position-fixed shadow-overlay w-100 h-100 d-flex justify-content-center align-items-center" 
        style={{zIndex:500, top:0}}>
            <div className= "w-50 h-75 d-flex flex-column align-items-center p-3 rounded drop-shadow bg-white">
                <button 
                className="bg-white border-0 d-block mt-0 ms-auto" 
                onClick={()=>props.setChangePassword(false)}
                >
                    <i className="fas fa-times text-custom font-16pt"></i>
                </button>
                <h3
                    className="mb-3"
                >Change password</h3>
                <FloatingLabel
                    label='Old Password*'
                    className={inputCommonClass+ ' col-10'}
                    >   
                        <Form.Control 
                            type="password"
                            placeholder='text'
                            className='border-0 border-bottom'
                            value={oldPassword}
                            onChange={(e)=> setOldPassword(e.target.value)}
                        />
                </FloatingLabel>
                <FloatingLabel
                    label='New Password*'
                    className={inputCommonClass+ ' col-10'}
                    >   
                        <Form.Control 
                            type="password"
                            placeholder='text'
                            className='border-0 border-bottom'
                            value={newPassword}
                            onChange={(e)=> setNewPassword(e.target.value)}
                        />
                </FloatingLabel>
                <FloatingLabel
                    label='Confirm new Password*'
                    className={inputCommonClass+ ' col-10'}
                    >   
                        <Form.Control 
                            type="password"
                            placeholder='text'
                            className='border-0 border-bottom'
                            value={confirmNewPassword}
                            onChange={(e)=> setConfirmNewPassword(e.target.value)}
                        />
                </FloatingLabel>
                <Button 
                className="bg-custom mt-5 ms-2"
                onClick={()=>changePassword()}
                >Change Password</Button>
              </div>
            </div>
    )
}