import { useSelector } from "react-redux"

import Location from "./location"
import Report from "../report"


export default function Admin () {
    let screen = useSelector(state=>state.screen)

    return(
        <div className="vh-100 d-flex align-items-center">
            {screen==='report'?<Report/>:<Location/>}
        </div>
    )
}
