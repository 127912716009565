import { Form, FloatingLabel, Button, Dropdown, Tabs, Tab } from "react-bootstrap"
import React,{useState} from "react";
import API from "../API/index";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";

export default function RegisterForm(props) {
    const [contact, setContact] = useState([0])
    const [parentContact, setParentContact] = useState([
            {
                relation:'',
                name:'',
                phone:'',
                email:'',
            }]
        )
    let dbAccess = useSelector(state => state.DbAccess)
    let token = useSelector(state=>state.token)
    const dispatch = useDispatch();

    const [lastname, setLastname] = useState('');
    const [firstname, setFirstname] = useState('');
    const [gender, setGender] = useState('');
    const [studentEmail, setStudentEmail] = useState('');
    const [studentPhone, setStudentPhone] = useState('');
    const [ethnicity, setEthnicity] = useState('');
    const [grade, setGrade] = useState('');
    const [hearFrom, setHearFrom] = useState('');
    const [address, setAddress] = useState('');
    const [school, setSchool] = useState('');

    const [subjects, setSubjects] = useState([]);
    const [key, setKey]= useState('profile')

    //Schedule 
    const [schedule, setSchedule] = useState([0])
    const [scheduleList, setScheduleList] = useState([
        {
            date:'',
            time:'',
            duration:''
        }
    ])
    const removeSchedule = () => {
        setSchedule(item=>{
            let prevState = [...item]
            prevState.pop()
            return [...prevState]
        })
        setScheduleList(scheduleList=>{
            let prevState = [...scheduleList]
            prevState.pop()
            return [...prevState]                      
        })
    }
    const updateScheduleState = (index, key, value) => {
        setScheduleList(scheduleList=>{
            let prevState = [...scheduleList]
            prevState[index][key] = value
            return [...prevState]
        })
    }
    const addSchedule=(id)=>{
        API.addSchedule(token, id, scheduleList)
        .then(()=>{
            props.RetrieveStudent(props.info[0].uid)
            props.setScheduleForm&&props.setScheduleForm(false)
        })
        .catch((Error)=>{
            console.log(Error)
        })
    }
    //==========================================
    const handleClick = () => {
        props.setRegisterForm&&props.setRegisterForm(false)
        props.setEditForm&&props.setEditForm(false)
    }
    const onChangehandler = (value) =>{
        setSubjects(
            subjects.filter(items=>items==='math'||items==='science'||items==='english').concat(value)
        )
    }
    const updateContactState = (index, key, value) => {
        setParentContact(parentContact=>{
            let prevState = [...parentContact]
            prevState[index][key] = value
            return [...prevState]
        })
    }
    const removeContactForm = () => {
        setContact(contact=>{
            let prevState = [...contact]
            prevState.pop()
            return [...prevState]
        })
        setParentContact(parentContact=>{
            let prevState = [...parentContact]
            prevState.pop()
            return [...prevState]                      
        })
    }
    const updateStudentContact=(id)=>{
        API.updateStudentContact(token, id, parentContact)
             .then(()=>{
                    props.setContactForm&&props.setContactForm(false)
             })
             .catch((error)=>
             console.log(error))
    }
    useEffect(()=>{
        if(props.setEditForm){
            setLastname(props.info[0].lastname||'')
            setFirstname(props.info[0].firstname||'')
            setGender(props.info[0].gender||'')
            setStudentEmail(props.info[0].email||'')
            setStudentPhone(props.info[0].phone||'')
            setEthnicity(props.info[0].ethnicity||'')
            setGrade(props.info[0].grade||'')
            setHearFrom(props.info[0].hearfrom||'')
            setAddress(props.info[0].address||'')
            setSchool(props.info[0].school||'')
            setSubjects(JSON.parse(props.info[0].subject)||'')
            setParentContact((props.info[2].length>0&&props.info[2])||[{
                relation:'',
                name:'',
                phone:'',
                email:'',
            }])
            setKey(props.info[3])
            props.info[2].forEach((item, index)=>{
                setContact(contact=>{
                    let prevState = [...contact]
                    prevState[index] = index
                    return [...prevState]
                })
            })
            if(props.info[1]){
                setScheduleList((props.info[1].length>0&&props.info[1])||[{
                    date:'',
                    time:'',
                    duration:''
                }])
                props.info[1].forEach((item, index) => {
                    setSchedule(schedule=>{
                        let prevState = [...schedule]
                        prevState[index] = index
                        return [...prevState]
                    })
                });
            }
        }
    },[])
    const updateStudent=()=>{
        API.updateStudent(
            props.info[0].uid,
            token,
            props.info[0].registerdate,
            lastname,
            firstname, 
            studentEmail,
            ethnicity, 
            gender,
            studentPhone,
            grade, 
            school,
            address,
            subjects,
            hearFrom, 
            dbAccess,
            "active"
        )
        .then((response)=>{
            API.deleteSchedule(token, response.data[0].uid)
            .then(()=>{
                addSchedule(props.info[0].uid)
                props.RetrieveStudent(props.info[0].uid)
                props.setEditForm&&props.setEditForm(false)
            })
            API.deleteStudentContact(token, response.data[0].uid)
            .then(()=>{
                updateStudentContact(props.info[0].uid)
                props.RetrieveStudent(props.info[0].uid)
                props.setEditForm&&props.setEditForm(false)
            })
            .catch((error)=>{
                console.log(error)
            })
        })
        .catch((error)=>{
            console.log(error)
        })
    }
    const addNewStudent = () =>{
        lastname&&firstname&&studentEmail?
        API.addNewStudent(
            token,
            Date(),
            lastname,
            firstname, 
            studentEmail,
            ethnicity, 
            gender,
            studentPhone,
            grade, 
            school,
            address,
            subjects,
            hearFrom, 
            dbAccess,
            "active")
        .then((response)=>{
            if(response.data.message==="Student is already exist"){
                dispatch({ type: 'FEED_BACK_TYPE', payload: 'danger'})&&(
                    dispatch({ type: 'FEED_BACK_TEXT', payload: response.data.message})
                )&&dispatch({ type: 'SHOW_FEED_BACK', payload: true })
            }else{
                parentContact&&updateStudentContact(response.data.user.uid)
                scheduleList&&addSchedule(response.data.user.uid)
                props.RetrieveStudent(response.data.user.uid)
                props.setRegisterForm&&props.setRegisterForm(false)
            }
        })
        .catch((error)=>{
            console.log(error.response)
        })
        :
        (
            dispatch({ type: 'FEED_BACK_TYPE', payload: 'danger'})&&
            (
            !lastname&&dispatch({ type: 'FEED_BACK_TEXT', payload: "Please fill in student's lastname"})||
            !firstname&&dispatch({ type: 'FEED_BACK_TEXT', payload: "Please fill in student's firstname"})||
            !studentEmail&&dispatch({ type: 'FEED_BACK_TEXT', payload: "Please fill in student's email"})
            )&&dispatch({ type: 'SHOW_FEED_BACK', payload: true })
        )
    }
    let gradeOption = []
    for (var i = 1; i <= 13; i++) {
        gradeOption.push(i)
    }
    const inputCommonClass = 'text-custom secondary-font px-3 mb-3'
    return (
        <div 
            className="position-fixed shadow-overlay w-100 h-100 d-flex justify-content-center align-items-center"
            style={{top:0}}
        >
        <div className="w-75 h-75 p-3 rounded drop-shadow d-flex flex-column justify-content-between" style={{backgroundColor:'#ddd'}}>
            <div className="">
                <Button 
                        variant="link"
                        className="border-0 d-block me-0 ms-auto p-0" 
                        onClick={()=> handleClick()}
                    >
                        <i className="fas fa-times text-custom font-16pt"></i>
                </Button>
                <Tabs activeKey={key}
                    onSelect={(k)=> setKey(k)}
                    className="prime-font custom-tab-color"
                >
                    <Tab eventKey="profile" title="Student Profile">
                    <div className="drop-shadow">
                        <div className="row m-0 justify-content-start bg-white p-3 rounded" style={{height:'80%'}}>
                        <FloatingLabel
                            label='Last Name*'
                            className={inputCommonClass+ ' col-6'}
                        >   
                            <Form.Control 
                                placeholder='text'
                                className='border-0 border-bottom'
                                type='text'
                                value={lastname}
                                onChange={(e)=>setLastname(e.target.value)}
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            label='First Name*'
                            className={inputCommonClass + ' col-6'}
                        >   
                            <Form.Control  
                                placeholder='text'
                                className='border-0 border-bottom'  
                                type='text'
                                value={firstname}
                                onChange={(e)=>setFirstname(e.target.value)}
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            label='Student Phone'
                            className={inputCommonClass + ' col-6'}
                        >   
                            <Form.Control 
                                placeholder='text'
                                type='number'
                                className='border-0 border-bottom'  
                                value={studentPhone}
                                onChange={(e)=>setStudentPhone(e.target.value)}
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            label='Student Email'
                                className={inputCommonClass + ' col-6'}
                        >   
                            <Form.Control 
                                placeholder='text'
                                className='border-0 border-bottom'  
                                type='email'
                                value={studentEmail}
                                onChange={(e)=>setStudentEmail(e.target.value)}
    
                            />
                        </FloatingLabel>

                        <FloatingLabel
                            label='Ethnicity'
                            className={`${inputCommonClass} col-4`}
                        >   
                            <Form.Control 
                                placeholder='text'
                                className='border-0 border-bottom'  
                                value={ethnicity}
                                type='text'
                                onChange={(e)=>setEthnicity(e.target.value)}
    
                            />
                        </FloatingLabel>
                        <Dropdown className='col-4 mb-3 d-flex px-2 p-0 ps-2 border-0 border-bottom'>
                            <Dropdown.Toggle variant='white' className="text-left secondary-font text-custom w-100">
                                {gender?
                                    (gender==='m'?'Male':'Female')
                                :'Gender'}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={()=>setGender('m')}>Male</Dropdown.Item>
                                <Dropdown.Item onClick={()=>setGender('f')}>Female</Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <Dropdown className='col-4 mb-3 d-flex px-2 p-0 ps-2 border-0 border-bottom'>
                            <Dropdown.Toggle variant='white' className="text-left secondary-font text-custom w-100">
                                {grade?
                                   grade
                                :'Grade'}
                            </Dropdown.Toggle>
    
                            <Dropdown.Menu>
                                {gradeOption.map((item, index)=>
                                    <Dropdown.Item key={index} onClick={()=>setGrade(item!==13?item:'Post Grad')}>{item!==13?'Grade '+item:'Post Grad'}</Dropdown.Item>
                                )}
                            </Dropdown.Menu>
                        </Dropdown>
                        <FloatingLabel
                            label='School'
                            type='text'
                            className={inputCommonClass}
                        >   
                            <Form.Control 
                                placeholder='text'
                                className='border-0 border-bottom'  
                                value={school}
                                onChange={(e)=>setSchool(e.target.value)}
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            label='Address'
                            className={inputCommonClass}
                        >   
                            <Form.Control 
                                placeholder='text'
                                type='text'
                                className='border-0 border-bottom'  
                                value={address}
                                onChange={(e)=>setAddress(e.target.value)}
                            />
                        </FloatingLabel>
                    
                        <FloatingLabel
                            label='Refer by'
                            className={inputCommonClass}
                        >   
                            <Form.Control 
                                placeholder='text'
                                type='text'
                                className='border-0 border-bottom'  
                                value={hearFrom}
                                onChange={(e)=>setHearFrom(e.target.value)}
                            />
                        </FloatingLabel>
                        <Form.Group className="d-flex align-items-center px-2">
                        <h5 className="text-custom m-0 secondary-font">Subject:</h5>
                            <Form.Check 
                                className="mx-2"
                                value='math' 
                                checked={subjects.filter(item=>item==='math').length>0}
                                onChange={()=>{
                                    subjects.filter(item=>item==='math').length>0?
                                    setSubjects(subjects=>subjects.filter(item=>item!=='math'))
                                    :
                                    setSubjects(subjects=>subjects.concat('math'))
                                }}
                                label="Math"
                            />
                            <Form.Check
                                className="mx-2"
                                value='science' 
                                checked={subjects.filter(item=>item==='science').length>0}
                                onChange={()=>{
                                    subjects.filter(item=>item==='science').length>0?
                                    setSubjects(subjects=>subjects.filter(item=>item!=='science'))
                                    :
                                    setSubjects(subjects=>subjects.concat('science'))
                                }}
                                label="Science"
                            />
                            <Form.Check
                                className="mx-2"
                                value='english' 
                                checked={subjects.filter(item=>item==='english').length>0}
                                onChange={()=>{
                                    subjects.filter(item=>item==='english').length>0?
                                    setSubjects(subjects=>subjects.filter(item=>item!=='english'))
                                    :
                                    setSubjects(subjects=>subjects.concat('english'))
                                }}
                                label="English"
                            />
                            <Form.Control
                                placeholder="other(s)" 
                                defaultValue={
                                    subjects.filter(item=>
                                        item!=='english'&&item!=='math'&&item!=='science'
                                    )
                                }
                                onChange={(e)=>onChangehandler(e.target.value)}/>
                        </Form.Group>
                        </div>
                    </div>
                    </Tab>
                    <Tab eventKey="contact" title="Guardians' Contact">
                    <div className="row col m-0 rounded">
                    {contact.map((item, index)=>
                    <div key={index} className="bg-white p-2 row m-0 mb-2 drop-shadow ">
                        {/* <FloatingLabel
                            label='Relation'
                            className="col-6 text-custom secondary-font px-3 mb-3"
                        >   
                            <Form.Control 
                                placeholder="Relation"
                                type='text'
                                className='border-0 border-bottom'
                                value={parentContact[item]&&parentContact[item].relation}
                                onChange={(e)=>updateContactState(item,'relation', e.target.value)}
                            />
                        </FloatingLabel> */}
                        <Dropdown className='col-6 mb-3 d-flex px-2 p-0 ps-2 border-0 border-bottom'>
                            <Dropdown.Toggle variant='white' className="text-left secondary-font text-custom w-100">
                                {parentContact[item]&&parentContact[item].relation!==""?parentContact[item].relation : "Relation"}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={()=>updateContactState(item,'relation', "Mother")}>Mother</Dropdown.Item>
                                <Dropdown.Item onClick={()=>updateContactState(item,'relation', "Father")}>Father</Dropdown.Item>
                                <Dropdown.Item onClick={()=>updateContactState(item,'relation', "Guardian")}>Guardian </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <FloatingLabel
                            label='Name'
                            className="col-6 text-custom secondary-font px-3 mb-3"
                        >   
                            <Form.Control 
                                placeholder="Name"
                                type='text'
                                className='border-0 border-bottom'
                                value={parentContact[item]&&parentContact[item].name}
                                onChange={(e)=>updateContactState(item,'name', e.target.value)}
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            label='Cell'
                            className="col-6 text-custom secondary-font px-3 mb-3"
                        >   
                            <Form.Control 
                                placeholder="Cell"
                                type='number'
                                className='border-0 border-bottom'
                                value={parentContact[item]&&parentContact[item].phone}
                                onChange={(e)=>updateContactState(item,'phone', e.target.value)}
                            />
                        </FloatingLabel>
                        <FloatingLabel
                            label='Email'
                            type='email'
                            className="col-6 text-custom secondary-font px-3 mb-3"
                        >   
                            <Form.Control 
                                placeholder="Email"
                                className='border-0 border-bottom'
                                value={parentContact[item]&&parentContact[item].email}
                                onChange={(e)=>updateContactState(item,'email', e.target.value)}
                            />
                        </FloatingLabel>
                    </div>)}
                    <div className="mt-2 d-flex">
                        {contact.length<3&&
                        <Button
                            className="bg-custom"
                            onClick={()=>{
                                setContact(contact=>[...contact, contact.length]);
                                setParentContact(parentContact =>[...parentContact,
                                    {
                                        relation:'',
                                        name:'',
                                        phone:'',
                                        email:'',
                                    }
                                ])
                            }}
                        >
                            Add Contact
                        </Button>}
                        {contact.length>1&&
                        <Button
                            className="ms-2"
                            variant="danger"
                            onClick={()=>{removeContactForm()}}
                        >
                            Remove Contact
                        </Button>
                        }
                    </div>
                </div>
                </Tab>
                <Tab eventKey={'schedule'} title='Schedule'>
                    <div 
                        className="h-100 w-100"
                        style={{zIndex:100, top:0}}
                    >
                        <div className="bg-white w-100 mx-auto p-4 rounded drop-shadow">
                        {schedule&&schedule.map((item,index)=>
                        <div key={index} className="row m-0">
                            <FloatingLabel
                                label='Date'
                                className={`col-4 text-custom secondary-font px-3 mb-3`}
                            >
                            <Form.Control 
                                placeholder='Date'
                                className='border-0 border-bottom'
                                value={scheduleList[item]&&scheduleList[item].date}
                                onChange={(e)=>updateScheduleState(item,'date', e.target.value)}/>
                            </FloatingLabel>
                            
                            <FloatingLabel
                                label='Time'
                                className={`col-4 text-custom secondary-font px-3 mb-3`}
                            >
                            <Form.Control 
                                placeholder='Time' 
                                className='border-0 border-bottom'
                                value={scheduleList[item]&&scheduleList[item].time}
                                onChange={(e)=>updateScheduleState(item,'time', e.target.value)}/>
                            </FloatingLabel>

                            <FloatingLabel
                                label='Duration (hours)'
                                className={`col-4 text-custom secondary-font px-3 mb-3`}
                                
                            >
                            <Form.Control 
                                placeholder='Duration' 
                                className='border-0 border-bottom'
                                value={scheduleList[item]&&scheduleList[item].duration}
                                onChange={(e)=>updateScheduleState(item,'duration', e.target.value)}/>
                            </FloatingLabel>
                        </div>
                        )}
                        {/* <Button className="bg-custom inline-block me-2 " onClick={()=>addSchedule()}> Set Schedule</Button> */}
                        </div>
                        <div className="d-flex justify-content-start">
                                    <Button
                                        className="bg-custom inline-block me-2 mt-3 ms-2"
                                        onClick={()=>{
                                            setSchedule(schedule=>[...schedule, schedule.length]);
                                            setScheduleList(scheduleList =>[...scheduleList,
                                                {
                                                    date:'',
                                                    time:'',
                                                    duration:'',
                                                }
                                            ])
                                        }}
                                    >
                                        Add Schedule
                                    </Button>
                            {schedule.length>1&&
                            <Button 
                                variant='danger' 
                                className="inline-block mt-3"
                                onClick={removeSchedule}>
                                    Remove Schedule
                            </Button>
                            }
                        </div>
                    </div>
                </Tab>
                
                </Tabs>
            </div>
                <div className=" w-100 me-auto ms-0 d-flex justify-content-end">
                        <Button 
                            className="bg-custom text-center"
                            onClick={() =>props.setEditForm? updateStudent():addNewStudent()}
                            >
                        {props.setEditForm?'Update Profile':'Create Student'}
                        </Button>
                        <Button 
                            className="bg-danger border-0 ms-2 text-center"
                            onClick={()=> handleClick()}>
                        Cancel
                        </Button>
                </div>
        </div>
    </div>
    )
}

