
import { Form, FloatingLabel, Button } from "react-bootstrap"
import { useSelector } from "react-redux"
import API from "../API/index";
import { useEffect, useState } from "react";

export default function RegisterSaleForm({setRegisterSaleForm}) {
    const handleClick = () => {
        setRegisterSaleForm(false)
    }
    const [showSaleRepList, setShowSaleRepList] = useState();
    const [updateSale, setupdateSale] = useState();
    let token = useSelector(state=>state.token)
    const dbAccess = useSelector(state=>state.DbAccess)
    const [name, setName]= useState() 
    const [email, setEmail]= useState() 
    const [password, setPassword]= useState() 
    const [saleId, setSaleId]= useState()

    const addSaleRep=()=>{
        API.addSaleRep(token, name , email, password, dbAccess)
        .then((response)=>{
            setShowSaleRepList(showSaleRepList=>[...showSaleRepList, response.data.user])
            setName('')
            setEmail('')
            setPassword('')
        })
        .catch((Error)=>{
            console.log(Error)
        }
        )
    }
    const showSaleRep= (id)=>{
        const saleRep=showSaleRepList.filter((user)=>user.uid===id)
        setName(saleRep[0].name)
        setEmail(saleRep[0].email)
        setSaleId(id)
        // setPassword(saleRep[0].password)
        setupdateSale(!updateSale)
    }  

    const updateSaleRep=()=>{
       API.updateSaleRep(token, saleId, name, email, password, dbAccess)
       .then(()=>{
        API.getSaleList(token)
        .then((response)=>{
            setShowSaleRepList(response.data.filter(item=>item.dtb_table_access===dbAccess))
        })
        .catch((Error)=>
        console.log(Error))
        setName('')
        setEmail('')
        setPassword('')
        setupdateSale(!updateSale)
       })
       .catch( error=>{
           console.log(error)
       })
    }


    const disabledSaleRep=(id, salerep)=>{
        API.updateSaleRep(token, id, salerep.name, salerep.email, salerep.password, salerep.dbAccess, !salerep.disabled)
        .then((response)=> {
            setShowSaleRepList(response.data.filter(item=>item.dtb_table_access===dbAccess))
        }
        )
        .catch((Error)=>{
            console.log(Error)
        })
    }
    useEffect(()=>{
        API.getSaleList(token)
        .then((response)=>{
            setShowSaleRepList(response.data.filter(item=>item.dtb_table_access===dbAccess))
        })
        .catch((Error)=>
        console.log(Error))
    },[token, dbAccess])
    return (
        <div 
            className="position-fixed shadow-overlay w-100 h-100 d-flex justify-content-center align-items-center" 
            style={{zIndex:500, top:0}}>
            <div className="bg-white h-75 w-75 h-50 row m-0 justify-content-center rounded py-2">
            <div>
                <Button 
                        className="bg-white border-0 d-block me-0 ms-auto" 
                        onClick={()=> handleClick()}
                    >
                        <i className="fas fa-times text-custom font-16pt"></i>
                </Button>
            </div>
            <div className="d-flex m-0 p-0 align-items-center w-100 px-5">
                <div className="row w-100 mx-1">
                <FloatingLabel
                    label='Name'
                    className={`col-4 text-custom secondary-font px-3 mb-3`}
                >
                <Form.Control 
                    placeholder='Name'
                    className='border-0 border-bottom'
                    value={name}
                    onChange={(e)=>setName(e.target.value)}/>
                </FloatingLabel>

                <FloatingLabel
                    label='Email'
                    className={`col-4 text-custom secondary-font px-3 mb-3`}
                >
                <Form.Control 
                    placeholder='Email'
                    className='border-0 border-bottom'
                    value={email}
                    onChange={(e)=>setEmail(e.target.value)}/>
                </FloatingLabel>

                <FloatingLabel
                    label='Password'
                    className={`col-4 text-custom secondary-font px-3 mb-3`}
                >
                <Form.Control 
                    placeholder='Date'
                    className='border-0 border-bottom'
                    value={password}
                    onChange={(e)=>setPassword(e.target.value)}/>
                </FloatingLabel>
                
                </div>
                <div className='m-0 p-0'>
                <Button 
                    className="bg-custom"
                    style={{width:'120px'}}
                    onClick={()=> updateSale?updateSaleRep():addSaleRep()}
                >
                    {updateSale?'Update':'Add'}
                </Button></div>
            </div>
            <div className="mb-3 mx-3 h-75 drop-shadow overflow-auto" style={{backgroundColor:'rgba(0,0,0,0.1)', width:'85%'}}>
                    <div className="my-2 py-1 row mx-0 justify-content-between align-items-center" style={{backgroundColor: 'rgba(255,255,255,0.7)'}}>
                        <div className="col-8 d-flex text-start">
                            <p className="col-6 m-0">Name</p>
                            <p className="col m-0">Email</p>
                        </div>
                        <div className="col-2 d-flex justify-content-center">
                            <p className="m-0">Actions</p>
                        </div>
                    </div>
                {showSaleRepList&&showSaleRepList.sort((a,b)=>
                                   +a.disabled - +b.disabled
                                ).map((user, index) => 
                    <div key={index} className="my-2 row mx-0 justify-content-between">
                        <div className="col-8 d-flex text-start">
                            <p className="col m-0 ">{user.name}</p>
                            <p className="col m-0 ">{user.email}</p>
                        </div>
                        {index!==0&&<div className="col-2 d-flex justify-content-between">
                            <Button variant='link' disabled={user.disabled!==0} className="col p-0"  onClick={()=> showSaleRep(user.uid)}>{updateSale?'Cancel':'Edit'}</Button>
                            {user.disabled===0?
                                <Button variant='link' className="col p-0 text-danger" onClick={()=> disabledSaleRep(user.uid, user)}>Disable</Button>
                                :
                                <Button variant='link' className="col p-0 text-danger" onClick={()=> disabledSaleRep(user.uid, user)}>Enable</Button>
                            }
                        </div>}
                    </div>    
                )}
            </div>
            </div> 
        </div>
    )
}
