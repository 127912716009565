const InitialState = {

    showPreload: false,
    
    //userInfo
    token:'',
    role:'',
    DbAccess:'',
    userInfo:'',


    screen:'',

    //feedback
    showfeedBack: false,
    feedBackType:'',
    feedBackText:'',

   
}
const rootReducer = (state = InitialState, action) => {
  switch (action.type) {
    case'SHOW_PRELOAD':
        return { ...state, showPreload: action.payload };

    //userInfo
    case 'SET_TOKEN':
        return { ...state, token: action.payload };
    case 'SET_ROLE':
        return { ...state, role: action.payload };
    case 'SET_LOCATION_ACCESS':
        return { ...state, DbAccess: action.payload };
    case 'SET_INFO':
        return { ...state, userInfo: action.payload };



    case 'SET_SCREEN':
        return { ...state, screen: action.payload };

    //feedback
    case 'SHOW_FEED_BACK':
        return { ...state, showfeedBack: action.payload };
    case 'FEED_BACK_TYPE':
        return { ...state, feedBackType: action.payload };
    case 'FEED_BACK_TEXT':
        return { ...state, feedBackText: action.payload };
    case 'LOG_OUT':
        return InitialState;
    default:
        return state;
  }
};

export default rootReducer;