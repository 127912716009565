import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import { Button, FloatingLabel, Form, Table } from "react-bootstrap"
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";

import API from "../../API/index.jsx"
import DeletePrompt from "../../components/deletePrompt.jsx";
import Feedback from "../../components/feedback.jsx";

export default function Location() {
    const dispatch = useDispatch();

    const [filter, setFilter] = useState();
    const [dbAccess, setDbAccess] = useState();

    const [locationForm, setLocationForm] = useState(false);
    const [editLocationForm, setEditLocationForm] = useState(false);

    const [authenticateUserList, setauthenticateUserList] = useState([])
    const [showLocationList, setShowLocationList] = useState();

    const [feedback, showFeedback] = useState(false);
    const [deleteLocationPrompt, setdeleteLocationPrompt]= useState(false)

    let token = useSelector(state=>state.token)

    useEffect(() => {
        API.getLocationList(token)
        .then((response)=>{
            
            setShowLocationList(response.data)
        })
        .catch((error)=>{
            alert(error.response.data.error);
        })
    }, [token])

    useEffect(() => {
        API.getAdminList(token)
        .then((response)=>{
            setauthenticateUserList(response.data)
        })
        .catch((error)=>{
            alert(error.response.data.error);
        })
    }, [token])
    const emailInput = useRef();
    const passwordInput = useRef();
    const locationInput = useRef();

    const joinLocation = (locationId) => {
        dispatch({ type: 'SET_LOCATION_ACCESS', payload: locationId })
    }
    
    const addLocation = () => {
        locationInput.current.value?
        (API.addNewLocation(locationInput.current.value, token)
        .then((response)=>{
            API.addSaleRep(token, response.data[0].name, "", "", response.data[0].uid)
            setShowLocationList(locations=>[...locations, response.data[0]])
        })
        .catch((error)=>{
            (dispatch({ type: 'FEED_BACK_TYPE', payload: 'danger'})&&
            dispatch({ type: 'FEED_BACK_TEXT', payload: error.response.data.error})&&
            dispatch({ type: 'SHOW_FEED_BACK', payload: true }))
        })&&
        setEditLocationForm(!editLocationForm))
        :
        (dispatch({ type: 'FEED_BACK_TYPE', payload: 'danger'})&&
        dispatch({ type: 'FEED_BACK_TEXT', payload: 'Please fill in required field'})&&
        dispatch({ type: 'SHOW_FEED_BACK', payload: true }))
    }



    const addNewAdmin = () =>{
        !emailInput.current.value&&!passwordInput.current.value?
        (!emailInput.current.value.match(/[@]/)?
        (dispatch({ type: 'FEED_BACK_TYPE', payload: 'danger'})&&
        dispatch({ type: 'FEED_BACK_TEXT', payload: 'Please fill in the correct email form'})&&
        dispatch({ type: 'SHOW_FEED_BACK', payload: true }))
        :
        (dispatch({ type: 'FEED_BACK_TYPE', payload: 'danger'})&&
        dispatch({ type: 'FEED_BACK_TEXT', payload: 'Please fill in the required field'})&&
        dispatch({ type: 'SHOW_FEED_BACK', payload: true }))
        )
        :
        API.registerUser(emailInput.current.value, passwordInput.current.value, 'location admin', dbAccess)
        .then((repsonse)=>{
            setauthenticateUserList(users=>[...users, repsonse.data.user])
        })
        .catch((error)=>{
            alert(error.response.data)
        })
    }
    const removeAdmin = (id) =>{
        API.removeAdmin(id, token)
        .then(()=>{
            setauthenticateUserList(users=>users.filter((items)=>items.uid!==id))
        })
        .catch((error)=>{
            alert(error.response.data.error);
        })
    }
    useEffect(()=>{
        dispatch({ type: 'SET_LOCATION_ACCESS', payload: 'all' })
    },[dispatch])
    const LocationForm = () => {
    return(
        locationForm&&<div 
            className="position-fixed w-100 h-100 shadow-overlay d-flex align-items-center justify-content-center" 
            style={{left:'0', top:'0'}}>
            <div className="bg-white w-50 h-75 p-4 rounded">
            <Button 
                className="bg-white border-0 d-block mt-0 me-0 ms-auto" 
                onClick={()=>setLocationForm(!locationForm)}
            >
                <i className="fas fa-times text-custom font-16pt"></i>
            </Button>
            <div className="row align-items-center justify-content-center">
                <FloatingLabel
                    label="Email"
                    className="text-custom secondary-font col"
                >
                    <Form.Control
                        type="email"
                        placeholder="email"
                        className="border-info border-0 border-bottom"
                        ref={emailInput}
                    />
                </FloatingLabel>
                <FloatingLabel
                    label="Password"
                    className="text-custom secondary-font col"
                >
                    <Form.Control
                        type="password"
                        placeholder="password"
                        className="border-info border-0 border-bottom"
                        ref={passwordInput}
                    />
                </FloatingLabel>
                <div className="d-flex justiy-content-center align-items-center" style={{width:'120px'}}>
                    <Button className="bg-custom d-block w-100" onClick={addNewAdmin}>Add</Button>
                </div>
                <div 
                    className="col-12 py-2 px-3 mt-3 overflow-auto"
                    style={{backgroundColor:'rgba(0,0,0,0.1)', height: '58vh'}}    
                >
                        <div className="my-2 py-1 px-5 d-flex justify-content-between" style={{backgroundColor: 'rgba(255,255,255,0.7)'}}>
                            <p className="text-left m-0">Email</p>
                            <p className="text-left m-0">Actions</p>
                        </div>
                    {authenticateUserList&&authenticateUserList.filter(user => user.location_access===dbAccess).map((user, index) => 
                        <div key={index} className="my-2 px-5 d-flex justify-content-between">
                            <p className="text-left m-0">{user.email}</p>
                            <Button variant='link' className="p-0 text-danger" onClick={()=>removeAdmin(user.uid)}>Delete</Button>
                        </div>
                    )}
                </div>
            </div>
            </div>
        </div>
        )
    }
    const EditLocationForm = () => {
        return(
            editLocationForm&&<div 
                className="position-fixed w-100 h-100 shadow-overlay d-flex align-items-center justify-content-center" 
                style={{left:'0', top:'0'}}>
                <div className="bg-white p-4 w-25 rounded">
                <Button 
                    className="bg-white border-0 d-block me-0 ms-auto" 
                    onClick={()=>setEditLocationForm(!editLocationForm)}
                >
                    <i className="fas fa-times text-custom font-16pt"></i>
                </Button>
    
                    <div className="mb-3">
                    <FloatingLabel
                        label="Input location name here..."
                        className="text-custom secondary-font"
                    >
                        <Form.Control
                            type="text"
                            placeholder="Location"
                            className="border-info border-0 border-bottom"
                            ref={locationInput}
                        />
                    </FloatingLabel>
                    </div>
                    <Button className="bg-custom d-block ms-auto me-0" onClick={addLocation}>Add</Button>
                </div>
            </div>
            )
        }
    return (
        
        <div className="w-100 mx-auto">
            {deleteLocationPrompt&&<DeletePrompt
            setdeleteLocationPrompt={setdeleteLocationPrompt}
            token={token}
            setShowLocationList={setShowLocationList}
            uid={deleteLocationPrompt}
        />}
            <LocationForm />
            <EditLocationForm/>
            <div 
                className="d-flex m-0 justify-content-center align-items-center fixed-top py-3 drop-shadow"
                style={{top:'48px', zIndex:10}}    
            >
            <FloatingLabel
                label="Search location name..."
                className="text-custom secondary-font col-4"
            >
                <Form.Control
                    type="email"
                    placeholder="email"
                    className="border-info border-0 border-bottom"
                    onChange={(e)=>setFilter(e.target.value)}
                />
            </FloatingLabel>
            <Button 
                className="bg-custom prime-font col-auto px-3 mx-2 border-0"
                onClick={()=>setEditLocationForm(!editLocationForm)}
            >
                Add location
            </Button>
            </div>
            <div 
                className="col-7 mx-auto p-3 position-relative rounded border overflow-auto" 
                style={{ height: '77vh', top:'65px'}}>
                <Table striped>
                <tbody>
                {showLocationList&&showLocationList.filter(items=>filter?!items.name.toLowerCase().indexOf(filter.toLowerCase()):true).map((item, index)=>
                    <tr key={index}>
                        <td className="d-flex justify-content-between">
                            <h5>{item.name}</h5>
                            <div className="d-flex align-items-center">
                                <Link to='/location' onClick={()=>joinLocation(item.uid)}><Button variant='link' className="py-0 border-0" >Join</Button></Link>
                                <Button variant="link" className="py-0 border-0" onClick={()=>{setLocationForm(!locationForm); setDbAccess(item.uid)}}>View admin profiles</Button>
                                <Button variant="link" className="py-0 border-0 text-danger" onClick={()=>{setdeleteLocationPrompt(item.uid)}}>Delete</Button>
                            </div>
                        </td>
                    </tr>
                )
                }
                </tbody>
                </Table>
            </div>
            {feedback&&<Feedback res={feedback.status} message={feedback.message} showFeedback={showFeedback}/>}
        </div>
    )
}