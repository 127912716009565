import { Nav, Dropdown } from "react-bootstrap"
import { useNavigate } from "react-router";
import { useSelector, useDispatch } from "react-redux"
import ChangePasswordForm from "./changePassword";
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUserAlt, faGear } from '@fortawesome/free-solid-svg-icons'

export default function GlobalNav() {
    let token = useSelector(state=>state.token)
    let role = useSelector(state=>state.role)
    let userInfo = useSelector(state=>state.userInfo.name)
    let view = useSelector(state=>state.screen)

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const[changePassword, setChangePassword]= useState(false)

    const Logout = async () => {
        await dispatch({ type: 'LOG_OUT' })
        navigate('/', {replace: true})

    }
    const switchView = (view) => {
        dispatch({ type: 'SET_SCREEN', payload: view })
    }


    return(
        <div className="fixed-top bg-custom" style={{zIndex: 100}}>
            {changePassword&&<ChangePasswordForm setChangePassword={setChangePassword}/>}
            <div className="col-10 d-flex align-items-center justify-content-between mx-auto my-2">
            <h3 className="m-0 prime-font text-white">Primus</h3>
            {token&&
            <div className="d-flex">
            {(role!=='sale rep')&&
            <Nav className="">
                <Nav.Item>
                    <Nav.Link disabled={view==='manage'} className={`${view==='manage'?'text-white-50':'text-white'}`} onClick={()=>switchView('manage')}>Manage</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link disabled={view!=='manage'} className={`${view==='manage'?'text-white':'text-white-50'}`} onClick={()=>switchView('report')}>Reports</Nav.Link>
                </Nav.Item>
            </Nav>
            }
                <Dropdown 
                    className="rounded mx-2"
                >
                    <Dropdown.Toggle 
                        className="border-0 lighter-hover"
                        style={{backgroundColor: 'rgba(255,255,255,0.2)'}}
                    >
                        {userInfo?userInfo:'More setting'}
                        {userInfo?<FontAwesomeIcon 
                            className='ps-2'
                            icon={faUserAlt}
                        />  
                        :<FontAwesomeIcon 
                        className='ps-2'
                        icon={faGear}
                    />  
                        }                       
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="shadow position-realtive">
                        <Dropdown.Item onClick={()=> setChangePassword(true)}>Change password</Dropdown.Item>
                        <Dropdown.Item className="text-danger" onClick={()=>Logout()}>Logout</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            }
            </div>
        </div>
    )
}