import { Accordion, AccordionButton } from "react-bootstrap"

export default function AccordionTab(props) {
    const headerComp = () => {

        return(
        <AccordionButton className="bg-custom text-white py-2" style={props.headerStyle? props.headerStyle:{}}>
            <p className="m-0 prime-font">{props.header}</p>
        </AccordionButton>
        )
    }
    return(
    <Accordion.Item eventKey={props.index} className='border-0'>
        <Accordion.Header as={headerComp}>
        </Accordion.Header>
        <Accordion.Body className="text-left">
            {props.children}
        </Accordion.Body>
    </Accordion.Item>
)
}