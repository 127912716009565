import { useSelector } from "react-redux";
import Report from "../report";
import LocationManage from "./manage";


export default function Manage () {
    let screen = useSelector(state=>state.screen)

    return(
        <div className="vh-100 d-flex align-items-center">
        {screen === 'report'? <Report/>:<LocationManage/> }
        </div>
    )
}
