import { useEffect, useState } from "react"
import { Button, FloatingLabel, Form, Accordion, Tabs, Tab } from "react-bootstrap"
import { useSelector, useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons'
import AccordionTab from "../../components/accordionTab"

import API from "../../API"

import RegisterSaleForm from "../../components/saleRepReg"
import RegisterForm from "../../components/studentsRegForm"
import PaymentForm from "../../components/newPayment"
import DeletePrompt from "../../components/deletePrompt"
import ContactForm from "../../components/parentContact"

export default function LocationManage () {
    const role = useSelector(state=>state.role)
    const DbAccess = useSelector(state=>state.DbAccess)
    const token = useSelector(state=>state.token)

    const dispatch = useDispatch();
    
    const [filter, setFilter] = useState();

    const [registerSaleForm, setRegisterSaleForm] = useState(false)
    const [registerForm, setRegisterForm] = useState(false)

    const [paymentForm, setPayMentForm]= useState(false)
    const [editPaymentForm, setEditPayMentForm]= useState(false)

    const [contactForm, setContactForm]= useState(false)

    const [studentList, setStudentList] = useState()
    const [studentInfo, setStudentInfo] = useState();
    const [studentSchedule, setStudentSchedule] = useState();
    const [studentContact, setStudentContact] = useState();
    const [studentBills, setStudentBills] = useState();

    const [saleRep, setSalRep] = useState();
    // const [enrol, setEnrol]= useState();
    const [editForm, setEditForm] = useState(false);
    const [deleteStudentPrompt, setDeleteStudentPrompt] = useState(false);
    const [deleteBill, setDeleteBill] = useState(false)
    const [key, setKey]= useState('profile')

    const RetrieveStudent = (id) => {
        setFilter('');
        API.getStudentInfo(id, token)
        .then(async (response)=>{
            setStudentInfo(response.data[0])
            await API.getStudentBillsList(token, [response.data[0].uid])
            .then((response)=>{
                setStudentBills(response.data)
            }
            )
            .catch((error)=>console.log(error))
            // await API.getStudentEnrolListbyStudentid(token, response.data[0].uid)
            // .then((response)=>{
            //     setEnrol(response.data)
            // })
            // .catch((error)=>{
            //     console.log(error)
            // })

            await API.getStudentContact(response.data[0].uid, token)
            .then((response)=>setStudentContact(response.data))
            .catch((error)=>console.log(error))
            
            await API.getStudentSchedule(response.data[0].uid, token)
            .then((response)=>setStudentSchedule(response.data))
            .catch((error)=>console.log(error))

        })
        .catch((error)=>console.log(error))
    }
//-----------------------------------------
    const disableStudent=(id)=>{
       API.disableStudent(token, id)
       .then((response)=>{
        RetrieveStudent(response.data.user[0].uid)
       })
       .catch((error)=>{
           console.log(error)
       })
    }
    const enableStudent=(id)=>{
       API.enableStudent(token, id)
       .then((response)=>{
        RetrieveStudent(response.data.user[0].uid)
       })
       .catch((error)=>{
           console.log(error)
       })
    }

//------------------------------------------
    const handleDuplicateBill = (bill) => {
        API.addStudentBills(
            token,
            bill.studentid, 
            bill.locationid, 
            new Date(), 
            bill.period_start, 
            bill.period_end, 
            bill.sale_rep_id, 
            bill.commissions, 
            bill.recurring_type, 
            bill.hour_per_session,
            bill.rate_per_hour, 
            bill.rate_per_month, 
            bill.percent_off, 
            bill.tutoring_supply, 
            bill.total, 
            bill.paid, 
            bill.deposit, 
            bill.refund, 
            bill.number_of_days,
            bill.number_of_month, 
            bill.commission_by_day, 
            bill.package_type, 
            bill.comment,
            null, //enrolmentid 
            bill.commission_by_date, 
            bill.total_by_date, 
            bill.totalhour, 
            bill.outstanding,
            bill.reschedule_fee, 
            bill.referred_by, 
            bill.cashback
        )
        .then((response)=>{
            console.log(response)
            API.getStudentBillsList(token, [response.data.bill.studentid])
            .then((response)=>{
                setStudentBills(response.data)
            })
        })
        .catch(error=>console.log(error));
    
    };
//------------------------------------------

    const toLocation = () => {
        dispatch({ type: 'SET_SCREEN', payload: 'manage' })
    }

//------------------------------------------
    useEffect(()=>{
        API.getSaleList(token)
        .then((response)=>{
            setSalRep(response.data.filter(item=>item.dtb_table_access===DbAccess))
        })
        .catch((Error)=>
        console.log(Error))
        // API.getAllEnrolList(token)
        // .then((response)=>{
        //     setEnrol(response.data)
        // })
    },[token, DbAccess])

    useEffect(() => {
        API.getStudentList(token, DbAccess)
        .then((response) =>{
            setStudentList(response.data)
        }
        )
        .catch((error)=>
            console.log(error)
        )
    },[token, DbAccess])
    return (
        <div 
            className="w-100 h-100 m-0 pt-5 overflow-hidden"
            style={{backgroundColor:'rgba(0,0,0,0.1'}}    
        >
        {registerForm&&<RegisterForm setRegisterForm={setRegisterForm} RetrieveStudent={RetrieveStudent}/>}
        {editForm&&<RegisterForm setEditForm={setEditForm}  RetrieveStudent={RetrieveStudent} info={[studentInfo, studentSchedule, studentContact, key]}/>}
        {registerSaleForm&&<RegisterSaleForm setRegisterSaleForm={setRegisterSaleForm} />}
        {paymentForm&&<PaymentForm  setPayMentForm={setPayMentForm} RetrieveStudent={RetrieveStudent} info={[studentInfo]}/>}
        {editPaymentForm&&<PaymentForm  setEditPayMentForm={setEditPayMentForm}  billid={editPaymentForm} info={[studentInfo]} RetrieveStudent={RetrieveStudent}/>}
        {contactForm&&<ContactForm  setContactForm={setContactForm} RetrieveStudent={RetrieveStudent} info={studentInfo} />}
        {deleteStudentPrompt&&<DeletePrompt 
            setDeleteStudentPrompt={setDeleteStudentPrompt} 
            uid={deleteStudentPrompt}
            token={token}
            info={[studentBills]}
            setStudentInfo={setStudentInfo}
            setStudentList={setStudentList}
        />}
        {deleteBill&&<DeletePrompt
            setDeleteBill={setDeleteBill}
            info={studentInfo} 
            billid={deleteBill}
            token={token}
            setStudentBills={setStudentBills}
            RetrieveStudent={RetrieveStudent}
        />}
            <div className="col-12 row m-0 bg-white py-3 justify-content-center align-items-center shadow">
                {
                role==='admin'&&
                <div className="col-auto mx-2 ">
                        <Link
                            className="prime-font text-custom"
                            to={'/admin'}
                            onClick={()=>toLocation()}
                            >
                            <FontAwesomeIcon 
                                className='pe-2'
                                icon={faChevronLeft}
                            />
                            Location
                        </Link>
                </div>
                }
                <FloatingLabel
                    label="Input student name or phone number..."
                    className="text-custom secondary-font col-4 py-0"
                >
                    <Form.Control
                        type="text"
                        placeholder="text"
                        className="border-info border-0 border-bottom position-relative"
                        defaultValue={filter}
                        onChange={(e)=>setFilter(e.target.value)}
                    />
                    <div className="bg-white drop-shadow mt-2 position-absolute w-100" style={{zIndex:'5'}}>
                        {/* student list */}
                        {filter&&studentList.filter((student=>
                            (student.firstname.toLowerCase().indexOf(filter.toLowerCase())!==-1)||
                            (student.lastname.toLowerCase().indexOf(filter.toLowerCase())!==-1)||
                            (student.phone&&student.phone.toLowerCase().indexOf(filter.toLowerCase())!==-1)
                        )).map((student, index)=>
                        <div
                            key={index}
                            className="d-flex justify-content-between px-4 py-3 pointer" 
                            onClick={()=>{RetrieveStudent(student.uid)}}
                        >
                            <div>{student.firstname} {student.lastname}</div>
                            {/* <div>Grade {student.grade}</div> */}
                            <div>{student.phone}</div>
                        </div>
                        )}
                    </div>
                </FloatingLabel>
                <Button className="bg-custom col-auto px-3 mx-2 prime-font" onClick={()=>setRegisterForm(true)}>
                    Add student
                </Button>
                {role!=='sale rep'&&
                    <Button className="bg-custom col-auto px-3 prime-font" onClick={()=>setRegisterSaleForm(true)}>
                        View sales'profile
                    </Button>}
            </div>
            {/* Student Info Column */}
            {studentInfo?
            <div className="row m-0 mt-4 justify-content-around h-100">
                <div className="col-6 px-4">
                    <div className="drop-shadow p-0 bg-white" style={{height:'60vh'}}>
                    <Tabs activeKey={key}
                      onSelect={(k)=> setKey(k)}
                      className="mb-1 prime-font bg-custom tab-custom"> 
                        <Tab eventKey="profile" title="Student Profile" className="bg-white pb-2 h-100">
                        <div className="px-4 text-left pt-3">
                            <b>Basic Info:</b>
                                <div className="ps-4">
                                    <div><b>Registered Date: </b>
                                        {new Intl.DateTimeFormat("en-GB",{
                                        year: "numeric",
                                        month: "short",
                                        day: "2-digit"
                                        }).format(new Date (Date(studentInfo.registerdate)))}
                                    </div>
                                    <div><b>Name:</b> {studentInfo.firstname} {studentInfo.lastname}</div>
                                    <div><b>Ethnicity:</b> {studentInfo.ethnicity}</div>
                                    <div><b>Gender:</b> {(studentInfo.gender==='m'&&'Male')||(studentInfo.gender==='f'&&'Female')} </div>
                                    <div><b>Email:</b> {studentInfo.email} </div>
                                    <div><b>Grade:</b> {studentInfo.grade} </div>
                                    <div><b>Address:</b> {studentInfo.address} </div>
                                    <div><b>Hear From:</b> {studentInfo.hearfrom} </div>
                                </div>
                            </div>
                            <br/>
                            <div className={`${!studentInfo.subject&&'d-flex align-items-center'} mx-4 text-left`}>
                                <b>Subject:</b>
                                <div className="ps-4 mt-2">
                                {studentInfo.subject&&JSON.parse(studentInfo.subject).length>0?
                                JSON.parse(studentInfo.subject).map((item)=>
                                    item +', '
                                )
                                :
                                <Button variant='outline-dark' onClick={()=>setEditForm(true)}>Add Subjects</Button>
                                }
                                </div>
                            </div>
                            <div className="d-flex justify-content-end px-4">

                            </div>
                          
                        </Tab>
                        <Tab eventKey="contact" title="Contact" className="bg-white pb-2 pt-3">
                        <div className={`${!studentContact&&'d-flex align-items-center'} mx-4 text-left`}>
                                <div className="">
                                {
                                studentContact&&studentContact.length>0?
                                studentContact.map((item, index)=>
                                <div key={index} className='my-2'>
                                    <div><b>{item.relation.toUpperCase()} - {item.name}</b></div>
                                    <div><b>Phone:</b> {item.phone}</div>
                                    <div><b>Email:</b> {item.email}</div>
                                </div>
                                )
                                :
                                <div className="w-100">
                                    No contact to show
                                </div>
                                }
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="schedule" title="Schedule" className="bg-white pb-2 pt-3">
                            <div className="mx-4 text-left">
                                    {studentSchedule&&studentSchedule.length?
                                    <>
                                    <div>
                                        <b>{studentSchedule.reduce((a, b)=>a+parseInt(b.duration), 0)} hour(s)</b> per week
                                    </div>
                                    {studentSchedule.map((item,index)=>
                                        <div className="my-2 d-flex mx-0 justify-content-between" key={index}>
                                            <b className="me-2">{item.date}:</b>
                                            <div className="col">
                                                <p className='m-0'>{item.time} <b className="ms-3">{item.duration} hour(s)</b></p>
                                            </div>
                                        </div>
                                    )}
                                    </>
                                    :
                                    <div className="w-100">
                                        No schedule to show
                                    </div>}
                                </div>
                        </Tab>
                    </Tabs>
                    </div>
                    <div className="my-3 d-flex justify-content-end">
                            <Button 
                                className="border-0 bg-custom" 
                                disabled={studentInfo.active==='disable'}
                                onClick={()=>setEditForm(true)}
                                >
                                    Edit Student
                            </Button>
                        <Button 
                            className="mx-3 bg-custom" 
                            onClick={()=>
                                studentInfo.active==='disable'?enableStudent(studentInfo.uid):disableStudent(studentInfo.uid)}    
                        >{studentInfo.active==='disable'?'Enable student':'Disable student'}</Button>
                        <Button variant='danger' className="border-0" onClick={()=>setDeleteStudentPrompt(studentInfo.uid)}>Delete student</Button>
                    </div>
                </div>
                {/* Student Billing Column */}
                <div className="col-6 px-4 h-100">
                <div className="drop-shadow w-100 overflow-y-scroll" style={{maxHeight:'80%'}}>
                <Accordion defaultActiveKey={['0']} alwaysOpen className="drop-shadow">
                <div className="w-50 px-4 position-fixed" style={{right:0, zIndex:2}}>
                    <div className="bg-custom d-flex justify-content-between">
                        <p className="text-left text-white prime-font m-0 py-2 px-3">Student Payment</p>
                        <Button 
                            variant='link' 
                            className="text-white"
                            disabled={studentInfo.active==='disable'}
                            onClick={()=>setPayMentForm(true)}
                        >
                            New Payment
                        </Button>
                    </div>
                </div>
                <div style={{height:'40px'}}></div>
                    {studentInfo&&studentInfo.active==='disable'?
                    <div className="w-50 position-fixed mt-5 d-flex justify-content-center">
                        <b>Enable student to see the Payment List</b>
                    </div>
                    :
                    studentBills&&studentBills.length>0?studentBills.sort((a, b)=>a.issue_date > b.issue_date&&-1).map((bill, index)=>
                    <AccordionTab 
                        key={index} 
                        header={new Intl.DateTimeFormat("en-GB",{
                            year: "numeric",
                            month: "short",
                            day: "2-digit"
                        }).format(new Date (bill.issue_date))} 
                        index={index.toString()}
                        headerStyle={{opacity:'0.6'}}
                    >
                        <div><b className="pe-2">Period:</b>
                        {new Intl.DateTimeFormat("en-GB",{
                            year: "numeric",
                            month: "short",
                            day: "2-digit"
                        }).format(new Date (bill.period_start))} - {new Intl.DateTimeFormat("en-GB",{
                            year: "numeric",
                            month: "short",
                            day: "2-digit"
                        }).format(new Date (bill.period_end))}</div>
                        <div className="row m-0">
                            <div 
                                className="col-5 d-flex justify-content-between my-3 py-2" 
                                style={{backgroundColor:'rgba(0,0,0,0.2)'}}
                            >
                                <div>
                                    <div>{bill.recurring_type==='Sessions'?'Total hours: ':'Number of month: '}</div>
                                    <div>{bill.recurring_type==='Sessions'?'Rate per hours: ':'Rate per month: '}</div>
                                    <div>%off:</div>
                                    <div>Tutoring Supply:</div>
                                    {bill.reschedule_fee!==0&&<div>Reschedule Fee:</div>}
                                    <br/>
                                    <div><b>Total:</b></div>
                                    {bill.refund&&<div><b>Refund:</b></div>}
                                    {bill.deposit&&<div><b>Deposit:</b></div>}
                                    <div><b>Paid:</b></div>
                                    <div><b>Outstanding:</b></div>
                                </div>
                                <div>
                                    <div>{bill.recurring_type==='Sessions'? bill.totalhour: bill.number_of_month}</div>
                                    <div>${bill.recurring_type==='Sessions'?bill.rate_per_hour: bill.rate_per_month}</div>
                                    <div>{bill.percent_off}%</div>
                                    <div>${bill.tutoring_supply}</div>
                                    {bill.reschedule_fee!==0&&<div>${bill.reschedule_fee}</div>}
                                    <br/>
                                    <div>${bill.total}</div>
                                    <div>{bill.refund?'$'+JSON.parse(bill.refund).amount: ''}</div>
                                    <div>{bill.deposit?'$'+JSON.parse(bill.deposit).reduce((a, b)=>a+parseInt(b.amount), 0):''}</div>
                                    <div>${JSON.parse(bill.paid).amount}</div>
                                    <div>${bill.outstanding}</div>
                                </div>
                            </div>
                            <div className="col-6 my-3 py-2 mx-2">
                                <div>
                                    {role!=='sale rep'&&
                                    <div><b>Commissions: </b>
                                        {saleRep.filter((sale)=>sale.uid===bill.sale_rep_id).length>0&&
                                        saleRep.filter((sale)=>sale.uid===bill.sale_rep_id)[0].name} - {bill.commissions}%
                                    </div>}
                                    <div><b>Reccuring Type:</b> {bill.recurring_type}</div>
                                    {role!=='sale rep'&&<div><b>Package Type:</b> {bill.package_type}</div>}
                                    {bill.referred_by?
                                    <div>
                                        <div><b>Referral Bonus: </b>{bill.referred_by} - ${bill.cashback}</div>
                                    </div>
                                    :
                                    ''
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-end">
                            <Link 
                                to={{
                                    pathname:'/printbill',
                                    search: '?id='+bill.uid,
                                }}
                                target='_blank'
                                className="text-custom"
                            >
                                <Button variant='link'>
                                    View/Print
                                </Button>
                            </Link>
                            <Button 
                                className="bg-custom mx-2" 
                                onClick={() => handleDuplicateBill(bill)}
                            >Duplicate Bill</Button>

                            <Button 
                                className="bg-custom" 
                                disabled={studentInfo.active==='disable'}
                                onClick={()=>setEditPayMentForm(bill.uid)}
                            >Edit Bill</Button>
                            <Button 
                                variant='danger' 
                                className="mx-2 border-0" 
                                onClick={()=>setDeleteBill(bill.uid)}
                            >Delete Bill</Button>
                        </div>
                        </AccordionTab>)
                    :
                    <div className="d-flex align-items-center justify-content-center">
                        Add Schedule to show Students Billing
                    </div>
                    }
                </Accordion>
                </div>
                </div>
            </div>
            :
            <div className="shadow-overlay w-100 h-100 d-flex justify-content-center align-items-center text-white">
                <h1>Find or add a student to start</h1>
            </div>
            }
        </div>
    )
}