import './App.css';
import React, { useEffect } from 'react';

import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import { useSelector } from 'react-redux';

import Manage from './screens/manage';
import Admin from './screens/admin';
import Login from './screens/login';

import PrintBill from './components/pdfrender';
import GlobalNav from './components/globalNav';
import Preload from './components/preload';

import Feedback from './components/feedback';

function App() {
  useEffect(() => {
    document.title = "Primus"
  })
  let showPreload = useSelector(state=>state.showPreload);
  let feedBack = {
      'show': useSelector(state=>state.showfeedBack),
      'type': useSelector(state=>state.feedBackType),
      'text': useSelector(state=>state.feedBackText)
  }

  return (
    <Router>
      <div className="App">
        {showPreload&&<Preload/>}
        <GlobalNav />
        <Routes>
          <Route path="/" exact element={<Login/>} />
          <Route path="/admin" element={<Admin/>} />
          <Route path='/manage' element={<Manage/>} />
          <Route path="/location" element={<Manage/>} />
          <Route path="/printbill" element={<PrintBill/>} />
        </Routes>
        {feedBack.show&&<Feedback type={feedBack.type} text={feedBack.text}/>}
      </div>
    </Router>
  )
}
export default App;
