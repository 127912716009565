import { Button } from "react-bootstrap"
import API from "../API"
import PaymentForm from "./newPayment"

export default function DeletePrompt(props) {

    const deleteStudent = () => {
        API.deleteStudent(props.uid, props.token)
        .then(()=>{
            props.setStudentInfo(null)
            props.setDeleteStudentPrompt(false)
            props.setStudentList(item=>item.filter(student=>student.uid!==props.uid))
            }
        )
        .catch(error=>console.log(error))
    }
    const deleteBill=()=>{
        API.deleteStudentBill(props.token, props.billid)
        .then(()=>{
          props.RetrieveStudent(props.info.uid) 
          API.deleteEnrol(props.token, props.billid)
          props.setDeleteBill(false) 
        })
    }
    const deleteLocation = () => {
        API.deleteLocation(props.uid, props.token)
        .then(()=>{
            props.setShowLocationList(locations=>locations.filter((item)=>item.uid!==props.uid))
            props.setdeleteLocationPrompt(false)
        })
        .catch((error)=>{
            alert(error.response.data.error);
        })
    }
    const deleteobj =()=>{
        if(props.setDeleteBill){
            deleteBill()
        }else if(props.setDeleteStudentPrompt){
            deleteStudent()
        }else if(props.setDeleteTag){
            if(props.tagid!==true){
                const index = props.info.findIndex(item=>{return item.id === props.tagid})
                console.log(index)
                props.info.splice(index, 1)
            }else{
                 props.info.splice(0, 1)
            }
            
        //  if(props.tagid===true){
        //     props.info.filter(item=> item.id!==0)
        //  }else{
        //     props.info.filter(item=> item.id!==props.tagid)
        //  }
        //  console.log(props.info)
         props.setDeleteTag(false)
        // console.log(props.info)
        }
        else {
            deleteLocation()
        }
    }
    const cancelDelete=()=>{
        if(props.setDeleteBill){
            props.setDeleteBill(false)
        }else if(props.setDeleteStudentPrompt){
            props.setDeleteStudentPrompt(false)
        }else if(props.setdeleteLocationPrompt){
            props.setdeleteLocationPrompt(false)
        }else{
            props.setDeleteTag(false)
        }
    }
    return (
        <div 
            className="h-100 w-100 position-absolute shadow-overlay d-flex align-items-center"
            style={{zIndex:100, top:0}}
        >
            <div className="row bg-white mx-auto p-4 rounded">
                <h5 className="col-12 mb-3 prime-font">Proceed to Deletion?</h5>
                {/* <Button variant='danger' className="inline-block me-2 col" onClick={()=>!props.setDeleteBill? props.setDeleteStudentPrompt? props.setDeleteStudentPrompt(false):props.setdeleteLocationPrompt(false): props.setDeleteBill(false)}>Cancel</Button> */}
                <Button variant='danger' className="inline-block me-2 col" onClick={()=>cancelDelete()}>Cancel</Button>
                <Button className="bg-custom inline-block col" onClick={deleteobj}>Yes</Button>
            </div>
        </div>
    )
}