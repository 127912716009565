import { Button, Image } from "react-bootstrap";
import printJS from "print-js";
import { useLocation } from "react-router";
import { useEffect, useState } from "react";
import API from "../API";
import { useSelector } from "react-redux";

export default function PrintButton() {
    const location = useLocation();

    const token = useSelector(state=>state.token);

    const copy = ['Customer', 'Office']
    
    const [billInfo, setBillInfo] = useState()
    const [studentInfo, setStudentInfo] = useState()
    const [saleRep, setSaleRep] = useState()
    const [enrolment, setEnrolment] = useState([])
    const [schedule, setSchedule]= useState([])


    useEffect(()=>{
        API.getStudentBilling(token, location.search.replace('?id=',''))
        .then((response)=>{
            setBillInfo(response.data)
            API.getStudentInfo(response.data[0].studentid, token)
            .then(response => {
                setStudentInfo(response.data)
            })
            .catch(error=>console.log(error))
            API.getStudentSchedule( response.data[0].studentid, token)
            .then(response=>{
              setSchedule(response.data)
            })
            .catch(error=>{
              console.log(error)
            })

            API.getStudentEnrolList(token, response.data[0].uid)
            .then(response=>{
              response.data&&setEnrolment(response.data)
            })
            .catch(error=>console.log(error))
        })
        .catch(error=>console.log(error));
    },[])

    useEffect(()=>{
        API.getSaleList(token)
        .then((response)=>{
          billInfo&&setSaleRep(response.data.filter(item=>item.uid===billInfo[0].sale_rep_id)[0])
        })
        .catch(error=>
          console.log(error)
        )
    },[billInfo])

    const RegroupSchedule = () => {
      let groupKey = 0;
      let groups = enrolment.reduce((r, o) => {
          let m = o.date.split((' '))[1];
          (r[m])? r[m].data.push(o) : r[m] = {group: String(groupKey++), data: [o]};
          return r;
      }, {});

      let result = Object.keys(groups).map((k)=>{ 
          return (groups[k]);
      });

      return (
        <div>
            {result&&result.map((item, index)=>
            <div key={index}>
                {item.data[0].date.split((' '))[1]} - {item.data.map(i=>i.date.split((' '))[0]  + ', ')}
            </div>
            )}
        </div>
      )
    }
    console.log(billInfo.reschedule_fee)
    return(
      (billInfo&&studentInfo)?
      <div className="vh-100 row m-0 justify-content-center align-items-center position-absolute w-100 h-100" style={{zIndex:'100', fontSize: '12pt'}}>
          <div className="overflow-auto bg-secondary h-100 pt-3">
            <div className="bg-white mx-auto my-0 p-0 drop-shadow d-flex align-items-center" style={{width:'8.5in', height:'11in'}}>
              <div id='printable-PDF' style={{height:'98.5%', width:'100%', margin:0, padding:0}}>
                {copy.map((item,index)=><div key={index} className={`h-50 m-0 row align-items-center justify-content-center text-left ${item==='Office'?'border-top':''}`}>
                    <div className="col-6"><Image src="./assets/logo.png" className="w-75"/></div>
                    <div className="col-5 text-center">{item} Copy</div>
                    <div className="col-11 p-0 row m-0 justify-content-center position-relative">
                    <div className="border-top position-absolute border-secondary" style={{top:'-20px', width:'95%', fontSize: '12pt'}}></div>
                      <div className="col-2 p-0">
                        <div>Issue Date:</div>
                        <div>Period:</div>
                      </div>
                      <div className="col-5 p-0">
                      <b>{new Intl.DateTimeFormat("en-GB", {
                          year: 'numeric',
                          month: "short",
                          day: "2-digit",
                        }).format(new Date(billInfo[0].issue_date))}</b>
                        <div className="d-flex">
                          <b>{new Intl.DateTimeFormat("en-GB", {
                              year: 'numeric',
                              month: "short",
                              day: "2-digit",
                          }).format(new Date(billInfo[0].period_start))}</b>
                          <div className="mx-2">-</div>
                          <b>{new Intl.DateTimeFormat("en-GB", {
                            year: 'numeric',
                            month: "short",
                            day: "2-digit",
                          }).format(new Date(billInfo[0].period_end))}</b>
                        </div>
                      </div>
                     <div className="col-4">
                          {item==='Office'&&
                            <div>
                                <div>Package: <b>{billInfo[0].package_type}</b></div>
                                {/* <div>Sales' Rep: <b>{saleRep&&saleRep.name}</b></div> */}
                                {/* <div>Commission: <b>{billInfo[0].commissions}%</b></div> */}
                            </div>
                          }
                     </div>
                    </div>
                    
                    <div className="row col-11 justify-content-center p-0 m-0">
                    <div className="col-6 p-0">
                     <div className="row mb-4">
                      <div className="col-4 p-0">
                        <div>Full name:</div>
                        {/* {studentInfo[0].email!=='NA'&&studentInfo[0].email&&<div>Student Email:</div>} */}
                        {schedule&&schedule.length>0&&<div>Schedule:</div>}
                      </div>
                      <div className="col-6 p-0">
                          <div><b>{studentInfo[0].firstname+' '+studentInfo[0].lastname}</b></div>
                          {/* {studentInfo[0].email!=='NA'&&studentInfo[0].email&&<div><b>{studentInfo[0].email}</b></div>} */}
                          {schedule.map(item=>
                            <div><b className="">{item.date+" " +item.time}</b></div>)
                          }
                      </div>
                      </div>
                      <div className="row">
                        <div className="col-11 p-0">
                          <div className="d-flex">
                            Enrol: 
                            <b className="ms-3 d-flex align-items-center">
                              <RegroupSchedule/>
                            </b>
                          </div>
                          <div>Comment: <b className="font-9pt">{billInfo[0].comment}</b></div>
                        </div>
                      </div>
                      </div>
                      <div className="col-5 row">
                      <div className="col p-0">
                        {billInfo[0].recurring_type==="Sessions"?
                        <div>
                          <div>Total Hour(s): </div>
                          <div>Rate per hour: </div>
                        </div>
                        :
                        <div>
                          <div>Number of Month(s): </div>
                          <div>Rate per month: </div>
                      </div>
                        }
                        <div>Discount: </div>
                        <div>Amount Save:</div>
                        <div>Tutoring Supply: </div>
                        {billInfo[0].reschedule_fee&&<div>Reschedule Fee: </div>}                   
                        <br/>
                        <div>Total:</div>
                        {billInfo[0].refund&&<div>Refund:</div>}
                        {billInfo[0].deposit&&<div>Deposit:</div>}
                        {billInfo[0].paid&&<div>Paid:</div>}
                        <div>Outstanding:</div>
                        {
                          item === 'Office' && billInfo[0].referred_by &&
                          <div>Referred Bonus:</div>
                        }
                      </div>
                      <div className="col-4 p-0">
                      {billInfo[0].recurring_type==="Sessions"?
                      <div>
                          <div>
                            <b>{billInfo[0].totalhour} hour(s)</b>
                          </div>
                          <div>
                            <b>${billInfo[0].rate_per_hour}</b>
                          </div>
                      </div>
                      :
                      <div>
                        <div>
                          <b>{billInfo[0].number_of_month}</b>
                        </div>
                        <div>
                          <b>${billInfo[0].rate_per_month}</b>
                        </div>
                      </div>
                      }
                          <div>
                            <b>{billInfo[0].percent_off}%</b>
                          </div>                          
                          {/* Amount Save */}
                            <div><b>${( (billInfo[0].totalhour * billInfo[0].rate_per_hour) * ( billInfo[0].percent_off/100) ).toFixed(2)}</b></div>
                          <div>
                            <b>${billInfo[0].tutoring_supply}</b>
                          </div>
                          {billInfo[0].reschedule_fee&&<div>
                            <b>${billInfo[0].reschedule_fee}</b>
                          </div>}

                          <br/>
                          {/* Subtotal */}
                          <div><b>${billInfo[0].total}</b></div>
                          {/* Refund */}
                          {billInfo[0].refund&&
                          <div><b>${JSON.parse(billInfo[0].refund).amount}</b></div>
                          }
                          {/* Deposit */}
                          {billInfo[0].deposit&&
                          <div><b>${JSON.parse(billInfo[0].deposit).reduce((a, b)=>a+parseInt(b.amount), 0)}</b></div>}
                          {/* Paid */}
                          <div><b>${JSON.parse(billInfo[0].paid).amount}</b></div>


                        {/* Outstanding */}
                          {billInfo[0].deposit?<div><b>$
                            {
                            (parseInt(billInfo[0].total) + parseInt(billInfo[0].refund&&JSON.parse(billInfo[0].refund).amount) - JSON.parse(billInfo[0].paid).amount - JSON.parse(billInfo[0].deposit).reduce((a, b)=>a+parseInt(b.amount), 0)).toFixed(2)
                            }</b></div>
                            :
                            <div><b>${(billInfo[0].total - JSON.parse(billInfo[0].paid).amount).toFixed(2)}</b></div>
                          }

                          {/* Referred By */}
                          {
                            item === "Office" &&
                            <div><b>{billInfo[0].referred_by&&billInfo[0].referred_by+' - '+billInfo[0].cashback&&'$'+ billInfo[0].cashback}</b></div>
                            
                          }
                      </div>
                    </div>
                    </div>
                    <b><div className="col-11 p-0 mx-auto d-flex justify-content-around align-items-center position-relative">
                    <div className="position-absolute border-secondary" style={{top:'-10px', width:'100%'}}></div>
                        <div>772 Kingsway, Vancouver, BC, Canada, V5V 3C1</div>
                        <div>(604) 707-0357</div>  
                        <div>datapi.com</div>
                    </div></b>
                </div>
                )}
                </div>
            </div>
          </div>
            <Button 
              className="bg-custom col-1 position-fixed drop-shadow" style={{right:'70px', bottom:'30px'}} 
              onClick={()=>printJS({printable:'printable-PDF', type: 'html', targetStyles: ['*'], font_size: ""
              })}
            >Print</Button>
        </div>:
        ''
    )
}