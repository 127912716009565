import axios from "axios";
import { api } from "./const";

export default class API{

    //=====================USER=====================================
    static async authenticate(username, password) {
        const data = {
            email: username,
            password: password
        }
        return axios.post(api+'login', data)
    }

    static async getUserInfo(token) {
        return axios.get(api+'userDetails', {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
    static async registerUser(email, password, role, location_id, token, name) {
        const data = {
            email: email,
            password: password,
            password_confirmation: password,
            role: role,
            location_access: location_id,
            name: name,
        }
        return axios.post(api+'register', data, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
    //==================LOCATION============================================
    static async getLocationList(token) {
        return axios.get(api+'location', {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
    static async addNewLocation(name, token) {
        return axios.post(api+'location?name='+name, {}, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
    })
    }
    static async deleteLocation(id, token) {
        return axios.delete(api+'location/'+id, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
    //-----------API for Student--------------------------------------------
    static async addNewStudent(token, register_date,  last_name, first_name, 
        email, ethnicity, gender, phone, grade, school, address, 
        subject, hear_from, location_id, active){
        const data = {
            registerdate: register_date,
            lastname:last_name,
            firstname:first_name,
            email:email,
            ethnicity:ethnicity,
            gender: gender,
            phone: phone,
            grade: grade,
            school:school,
            address: address,
            subject:JSON.stringify(subject),
            hearfrom: hear_from,
            locationid: location_id,
            active: active,
        }

        return axios.post(api+'student', data ,{
            'headers':{
                'Authorization':'Bearer '+token,
                'Content-Type': 'application/json'
            }
        })
    }
    static async updateStudent(uid,token, register_date,  last_name, first_name, email, ethnicity, gender, phone, grade, school, address, subject, hear_from, location_id){
        return axios.put(api+'student/' +uid, { 
        "registerdate": register_date,
        "lastname":last_name,
        "firstname":first_name,
        "email":email,
        "ethnicity":ethnicity,
        "gender": gender,
        "phone": phone,
        "grade": grade,
        "school":school,
        "address": address,
        "subject":JSON.stringify(subject),
        "hearfrom": hear_from,
        "locationid": location_id,} ,{
            'headers':{
                'Authorization':'Bearer '+token,
                'Content-Type': 'application/json'
            }
        })
    }
    static async getStudentList(token, location) {
        return location!=='all'?
        axios.get(api+'student/'+location, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
        :
        axios.get(api+'student', {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        });
    }

    static async getStudentInfo(id, token) {
        return axios.get(api+'studentInfo/'+id,{
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }

    static async deleteStudent(id, token) {
        return axios.delete(api+'student/'+id,{
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }

    static async disableStudent(token, id){
        return axios.get(api+'disableStudent/'+id,{
            'headers':{ 
                'Authorization': 'Bearer'+token
            }
        })
    }
    static async enableStudent(token, id){
        return axios.get(api+'enableStudent/'+id,{
            'headers':{ 
                'Authorization': 'Bearer'+token
            }
        })
    }
    //------------------------------------------------------------
    static async getAdminList(token) {
        return axios.get(api+'showAdminList', {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
    static async removeAdmin(id, token) {
        return axios.delete(api+'deleteAdmin/'+id, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
//-------------------BILL-------------------------------
    static async getAllStudentBill(token){
        
        return axios.get(api+'student_billings',{
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
    static async getStudentBillsList(token, studentIds) {
        return axios.post(api+'showStudentBillList', studentIds,{
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
    static async getStudentBilling(token, id){
        return axios.get(api+'student_billings/'+id,{
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
    static async addStudentBills(
        token,
        studentid, 
        locationid, 
        issue_date, 
        period_start, 
        period_end, 
        sale_rep_id, 
        commissions, 
        recurring_type, 
        hour_per_session,
        rate_per_hour, 
        rate_per_month, 
        percent_off, 
        tutoring_supply, 
        total, 
        paid, 
        deposit, 
        refund, 
        number_of_days,
        number_of_month, 
        commission_by_day, 
        package_type, 
        comment,
        enrolment_id, 
        commission_by_date, 
        total_by_date, 
        totalhour, 
        outstanding,
        reschedule_fee, 
        referred_by, 
        cashback
        ){
        const data={
            studentid: studentid,
            locationid: locationid,
            issue_date: issue_date,
            period_start: period_start,
            period_end: period_end,
            sale_rep_id: sale_rep_id,
            commissions: commissions,
            recurring_type: recurring_type,
            hour_per_session: hour_per_session,//null
            rate_per_hour: rate_per_hour,
            rate_per_month: rate_per_month,
            percent_off: percent_off,
            tutoring_supply: tutoring_supply,
            total: total,
            paid: paid,
            deposit: deposit,
            refund: refund,
            number_of_days: number_of_days,//null
            number_of_month: number_of_month,
            commission_by_day: commission_by_day,//null
            package_type: package_type,
            comment: comment,
            enrolment_id:enrolment_id,//null
            commission_by_date: commission_by_date,//null
            total_by_date: total_by_date,//null
            totalhour: totalhour,
            outstanding:outstanding,
            reschedule_fee:reschedule_fee,
            referred_by:referred_by,
            cashback:cashback
        }
        return axios.post(api+'student_billings',data, {
            'headers': {
                'Authorization': 'Bearer '+token
            }
        }
        )

    }
  
    static async deleteStudentBill(token, id){
        return axios.delete(api+'student_billings/'+id,{
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
//=================ENROL====================================
    static async addEnrol(token, enrolStr){
        
        return axios.post(api+'enrolments',{data: enrolStr},{
            'headers': {
                'Authorization': 'Bearer '+ token
            }
        })
    }
    static async getStudentEnrolListbyStudentid(token, studentid){
        return axios.get(api+'showbystudentid/'+studentid, {
            'headers':{
                'Authorization': 'Bearer '+ token
            }
        })
    }
    static async getStudentEnrolList(token, billid){
        return axios.get(api+'enrolments/'+billid, {
            'headers':{
                'Authorization': 'Bearer '+ token
            }
        })
    }
    static async getAllEnrolList(token){
        return axios.get(api+'enrolments', {
            'headers': {
                'Authorization': 'Bearer '+ token
            }
        })
    }
    static async deleteEnrol(token, billId){
        return axios.delete(api+'enrolments/'+ billId,{
            'headers':{
                'Authorization': "bearer "+token
            }
        })
    }

//-------------CONTACT-----------------------
    static async deleteStudentContact(token, id){
        return axios.delete(api+'studentContact/'+id,{
            'headers':{
                'Authorization':'Bearer '+token
            }
        })
    }
    static async getStudentContact(studentId, token) {
         return axios.get(api+'studentContact/'+studentId,{
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }

    static async getAllStudentContact(token){
        return axios.get(api+'studentContact',{
            'headers': {
                'Authorization': 'Bearer '+token
        }
    })
    }
    static async updateStudentContact(token, studentid, contactObj){
        contactObj.forEach((item)=>{
            let data={
                studentid : studentid,
                relation :item.relation,
                name : item.name,
                phone : item.phone,
                email : item.email
            };
            return axios.post(api+'studentContact', data,{
                'headers': {
                    'Authorization': 'Bearer '+token
                }
            })
        })
    }
//------------------SCHEDULE-----------------
    static async getStudentSchedule(studentId, token) {
        return axios.get(api+'student_schedule/'+studentId,{
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
    static async addSchedule(token, studentid, scheduleObj){
        scheduleObj.forEach((item)=>{
            const data={
                studentid: studentid,
                date: item.date,
                time: item.time,
                duration: item.duration
            }
            
            return axios.post(api+'student_schedule',data,{
                'headers': {
                    'Authorization': 'Bearer '+token
                }
            })
        })
    }
    static async deleteSchedule(token, studentid){
        return axios.delete(api+'student_schedule/'+studentid,{
            'headers':{
                "Authprization": 'Bearer '+token
            }
        })
    }
    //===========================SALEREP======================================
    static async addSaleRep(token, name, email, password, dtb_table_access){
        const data={
            name: name,
            email: email,
            password: password,
            dtb_table_access: dtb_table_access,
            disabled: 0
        }
        return axios.post(api+'salerep',data,{
            'headers': {
                'Authorization': 'Bearer '+token
            }
        })
    }
    static async getSaleList(token){
        return axios.get(api+'salerep',{
            'headers':{
                'Authorization': 'Bearer '+ token
            }
        })
    }

    static async updateSaleRep(token, uid, name, email, password, dtb_table_access, disabled){
        return axios.put(api+'salerep/'+uid, {
            "name": name,
            "email": email,
            "password": password,
            "dtb_table_access": dtb_table_access,
            "disabled": disabled
        },{
            'headers':{
                'Authorization': 'Bearer '+token
            }
        })
    }
    //================CHANGE PASSWORD=============================
    static async changepassword( token, uid, oldPassword, newPassword, confirmNewPassword){
        const data={
            oldPassword: oldPassword,
            newPassword: newPassword,
            confirmNewPassword: confirmNewPassword
        }
        return axios.put(api+ 'changePassword/'+ uid, data,{
            'headers':{
                'Authorization': 'Bearer '+token
            }
        })
    }
}