import { useState, useEffect } from "react"
import API from "../API"


export default function ContactView (props) {
    const labelRow = ['Name', "Student's contact"]
    const contactLabelRow = ['Other Contact 1', 'Other Contact 2','Other Contact 3']
    const [studentList, setStudentList] = useState()
    const [contactList, setContactList] = useState()
    useEffect(()=>{
        API.getStudentList(props.token, props.filter.location)
        .then(response=>{
            setStudentList(response.data)
        })
        API.getAllStudentContact(props.token)
        .then(response=>{
            setContactList(response.data)
        })
    },[props.token, props.filter])
    return (
        <div>
            <div className="row m-0 prime-font text-white" style={{background:'rgba(0,0,0,0.5)'}}>
                {labelRow.map((item, index)=>
                    <div key={index} className="col-2 border py-2">{item}</div>
                )}
                {contactLabelRow.map((item, index)=>
                    <div key={index} className="col border py-2">{item}</div>
                )}
            </div>
            {studentList&&studentList.filter(item=>props.filter.studentNameInput?
                (item.firstname.toLowerCase().indexOf(props.filter.studentNameInput.toLowerCase()) !== -1
                ||
                item.lastname.toLowerCase().indexOf(props.filter.studentNameInput.toLowerCase()) !== -1)
                :
                item
                ).map((item, index)=>
                <div key={index} className="d-flex seccondary-font m-0">
                    <div className="col-2 border py-2">
                        {item.firstname+" "+item.lastname}
                    </div>
                    <div className="col-2 border p-2 text-start">
                        <b>Email:</b> {item.email}<br/>
                        <b>Phone:</b> {item.phone}
                    </div>
                    <div className='col row mx-0 border' >
                    {contactList&&contactList.filter(contacts=>
                        contacts.studentid===item.uid).map((contact, index)=>
                            <div key={index} className="col-4 border py-2 text-start">
                                    <p className='my-0'><b>{contact.relation} - {contact.name}</b></p>
                                    <p className='my-0'><b>Cell:</b> {contact.phone}</p>
                                    <p className='my-0'><b>Email:</b> {contact.email}</p>
                            </div>
                    )}
                    </div>
                </div>
            )}
        </div>
    )
}