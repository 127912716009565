export default function Preload() {

    return (
        <div 
            className="position-absolute h-100 w-100 shadow-overlay d-flex justify-content-center align-items-center"
            style={{zIndex: 1000, top:0, left:0}}
        >
            <img src='./assets/preload.svg' alt=''/>
        </div>
    )
}