import { useEffect, useState } from "react"
import { Form, FloatingLabel, Button } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { useNavigate } from "react-router";

import "../../persist-redux/storage.jsx"
import API from "../../API/index.jsx"


export default function Login () {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const login = () => {
        !email||!password?
        (dispatch({ type: 'FEED_BACK_TYPE', payload: 'danger'})&&
        dispatch({ type: 'FEED_BACK_TEXT', payload: 'Please fill in required field'})&&
        dispatch({ type: 'SHOW_FEED_BACK', payload: true }))
        :
        dispatch({ type: 'SHOW_PRELOAD', payload: true })&&
        API.authenticate(email, password)
        .then((response)=>{
            dispatch({ type: 'SET_TOKEN', payload: response.data })
            API.getUserInfo(response.data)
            .then(async (response)=>{
                await dispatch({ type: 'SET_ROLE', payload: response.data[0].role })
                await dispatch({ type: 'SET_INFO', payload: {
                        email: response.data[0].email, 
                        id: response.data[0].id,
                        name: response.data[0].name,
                    }})
                await dispatch({ type: 'SET_LOCATION_ACCESS', payload: response.data[0].location_access })
                await dispatch({ type: 'SET_SCREEN', payload: 'manage' });
                await dispatch({ type: 'SHOW_PRELOAD', payload: false });
                response.data[0].role === 'admin'?
                navigate('/admin', {replace: true})
                :
                navigate('/manage', {replace: true})
            })
            .catch((error)=>{
                dispatch({ type: 'FEED_BACK_TYPE', payload: 'danger'})
                dispatch({ type: 'FEED_BACK_TEXT', payload: error.response.data.error}) 
                dispatch({ type: 'SHOW_FEED_BACK', payload: true })
                dispatch({ type: 'SHOW_PRELOAD', payload: false })
            })
        }).catch((error)=>{
            dispatch({ type: 'FEED_BACK_TYPE', payload: 'danger'}) 
            dispatch({ type: 'FEED_BACK_TEXT', payload: error.response.data.error}) 
            dispatch({ type: 'SHOW_FEED_BACK', payload: true })
            dispatch({ type: 'SHOW_PRELOAD', payload: false })
        })
    }
    const loginWithEnter = (e) =>{
        if (e.code === 'Enter') {
            login();
          }
    }
    useEffect(()=>{
        dispatch({ type: 'SHOW_PRELOAD', payload: false })
    },[])
    return(
        <div className="w-25 mx-auto d-flex flex-column justify-content-center vh-100">
            <h1 className="text-custom">Primus</h1>
            <FloatingLabel
                label="Email address"
                className="mb-3 text-custom secondary-font"
            >
                <Form.Control
                    type="email"
                    placeholder="name@example.com" 
                    className="border-info border-0 border-bottom" 
                    onChange={(e)=>setEmail(e.target.value.toLowerCase())}
                    onKeyPress={(e)=>loginWithEnter(e)}
                />
            </FloatingLabel>
            <FloatingLabel 
                label="Password" 
                className="text-custom mb-3 secondary-font"
            >
                <Form.Control 
                    type="password" 
                    placeholder="Password" 
                    className="border-info border-0 border-bottom" 
                    onChange={(e)=>setPassword(e.target.value)}
                    onKeyPress={(e)=>loginWithEnter(e)}
                />

            </FloatingLabel>
            <Button 
                className="mx-auto px-5 my-3 bg-custom border-0 prime-font" 
                onClick={()=>login()} 
            >
                Login
            </Button>
            <p className="text-custom">Forgot Password</p>
            {/* chnge to a tag when back end ready */}

        </div>
    )
}
